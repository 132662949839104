<template>
  <base-module v-bind="$props">
    <img src="@/assets/logo.svg" />
    <div class="text-heading">¡Hola{{ !nameEmpty ? ` ${name}` : "" }}!</div>
    <common-text class="text-title" :text="description" />
    <div v-if="alert != ''" class="mt-4">
      <v-icon color="#FF0C4F">$flower</v-icon>
      <common-text :text="alert" class="text-body" style="color: #1161f8" />
    </div>
  </base-module>
</template>
<script>
export default {
  name: "Welcome",

  props: {
    name: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
    alert: {
      required: false,
      type: String,
      default: "",
    },
    startColor: {},
    endColor: {},
  },
  computed: {
    nameEmpty() {
      if (this.name === null || this.name === "" || this.name === " ") {
        return true;
      }
      return false;
    },
  },
};
</script>
