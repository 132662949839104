export const programs = [
  {
    generic_program_name: "Ingeniería en Comercio Exterior",
    generic_program_code: 73,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Animación Digital",
    generic_program_code: 8,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Administración Turística y Hotelera",
    generic_program_code: 4,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Biotecnología y Bioingeniería",
    generic_program_code: 72,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Comercio Exterior",
    generic_program_code: 73,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name:
      "Ingeniería en Automatización, Instrumentación y Control",
    generic_program_code: 71,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Alimentos",
    generic_program_code: 70,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Biotecnología y Bioingeniería",
    generic_program_code: 72,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Otras Ingenierías",
    generic_program_code: 99,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Otras Ingenierías Civiles",
    generic_program_code: 100,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "896",
    institution_name: "Universidad de Aysén",
    region_name: "Aysén",
    region_id: 11,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Alimentos",
    generic_program_code: 70,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Otras Ingenierías",
    generic_program_code: 99,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Otras Ingenierías",
    generic_program_code: 99,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Biotecnología y Bioingeniería",
    generic_program_code: 72,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Biotecnología y Bioingeniería",
    generic_program_code: 72,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Alimentos",
    generic_program_code: 70,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Acuicultura y Pesca",
    generic_program_code: 69,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Alimentos",
    generic_program_code: 70,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Biotecnología y Bioingeniería",
    generic_program_code: 72,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Otras Ingenierías",
    generic_program_code: 99,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name:
      "Ingeniería en Automatización, Instrumentación y Control",
    generic_program_code: 71,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Traducción e Interpretación",
    generic_program_code: 131,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Traducción e Interpretación",
    generic_program_code: 131,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Otras Ingenierías Civiles",
    generic_program_code: 100,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Artes y Música",
    generic_program_code: 107,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Artes y Música",
    generic_program_code: 107,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Otros Profesionales de Tecnología",
    generic_program_code: 105,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Artes y Música",
    generic_program_code: 107,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Filosofía y Religión",
    generic_program_code: 113,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Otros Profesionales de Educación",
    generic_program_code: 104,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Otras Ingenierías Civiles",
    generic_program_code: 100,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Otras Ingenierías Civiles",
    generic_program_code: 100,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Otras Ingenierías Civiles",
    generic_program_code: 100,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Electricidad",
    generic_program_code: 77,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Otros Profesionales de Ciencias Básicas",
    generic_program_code: 102,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Otras Ingenierías Civiles",
    generic_program_code: 100,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Otras Ingenierías Civiles",
    generic_program_code: 100,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Otros Profesionales de Ciencias Sociales",
    generic_program_code: 103,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Otros Profesionales de Tecnología",
    generic_program_code: 105,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Otras Ingenierías Civiles",
    generic_program_code: 100,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Otros Profesionales de Arte y Arquitectura",
    generic_program_code: 101,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Pedagogía en Artes y Música",
    generic_program_code: 107,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Otros Profesionales de Arte y Arquitectura",
    generic_program_code: 101,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Licenciatura en Letras y Literatura",
    generic_program_code: 91,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Otros Profesionales de Educación",
    generic_program_code: 104,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Otros Profesionales de Educación",
    generic_program_code: 104,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Otros Profesionales de Educación",
    generic_program_code: 104,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Artes y Música",
    generic_program_code: 107,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Filosofía",
    generic_program_code: 35,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Filosofía y Religión",
    generic_program_code: 113,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Otros Profesionales de Ciencias Sociales",
    generic_program_code: 103,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Traducción e Interpretación",
    generic_program_code: 131,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bioquímica",
    generic_program_code: 23,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "68",
    institution_name: "Universidad Miguel de Cervantes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "68",
    institution_name: "Universidad Miguel de Cervantes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "68",
    institution_name: "Universidad Miguel de Cervantes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Biología",
    generic_program_code: 21,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "68",
    institution_name: "Universidad Miguel de Cervantes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Otras Ingenierías",
    generic_program_code: 99,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ciencias Políticas",
    generic_program_code: 24,
    institution_code: "68",
    institution_name: "Universidad Miguel de Cervantes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Publicidad",
    generic_program_code: 121,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Filosofía y Religión",
    generic_program_code: 113,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Filosofía y Religión",
    generic_program_code: 113,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Otros Profesionales de Tecnología",
    generic_program_code: 105,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Biología Marina y Ecología Marina",
    generic_program_code: 22,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Administración Turística y Hotelera",
    generic_program_code: 4,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Administración de Empresas e Ing. Asociadas",
    generic_program_code: 5,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "23",
    institution_name: "Universidad Adolfo Ibáñez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Otros Profesionales de Tecnología",
    generic_program_code: 105,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "23",
    institution_name: "Universidad Adolfo Ibáñez",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Artes y Música",
    generic_program_code: 107,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Artes y Música",
    generic_program_code: 107,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Paisajismo",
    generic_program_code: 106,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Publicidad",
    generic_program_code: 121,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Química, Licenciado en Química",
    generic_program_code: 125,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Publicidad",
    generic_program_code: 121,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Diseño Gráfico",
    generic_program_code: 30,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Técnico en Dibujo Técnico",
    generic_program_code: 134,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Biotecnología y Bioingeniería",
    generic_program_code: 72,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Comercio Exterior",
    generic_program_code: 73,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Administración Turística y Hotelera",
    generic_program_code: 4,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Química Industrial",
    generic_program_code: 123,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Psicopedagogía",
    generic_program_code: 120,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Otras Ingenierías Civiles",
    generic_program_code: 100,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Traducción e Interpretación",
    generic_program_code: 131,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Química, Licenciado en Química",
    generic_program_code: 125,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Química, Licenciado en Química",
    generic_program_code: 125,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Química Industrial",
    generic_program_code: 123,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Técnico en Mantenimiento Industrial",
    generic_program_code: 137,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Técnico en Construcción y Obras Civiles",
    generic_program_code: 133,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Técnico en Minería",
    generic_program_code: 139,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Técnico en Electricidad y Electricidad Industrial",
    generic_program_code: 135,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Técnico en Electrónica y Electrónica Industrial",
    generic_program_code: 136,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Química Industrial",
    generic_program_code: 123,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Química Ambiental",
    generic_program_code: 122,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Traducción e Interpretación",
    generic_program_code: 131,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Publicidad",
    generic_program_code: 121,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicopedagogía",
    generic_program_code: 120,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Publicidad",
    generic_program_code: 121,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Publicidad",
    generic_program_code: 121,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Geografía",
    generic_program_code: 38,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Artes y Música",
    generic_program_code: 107,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Geografía",
    generic_program_code: 38,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Electrónica",
    generic_program_code: 45,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Electrónica",
    generic_program_code: 45,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Geografía",
    generic_program_code: 38,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Realizador de Cine y Televisión",
    generic_program_code: 126,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Electrónica",
    generic_program_code: 45,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Realizador de Cine y Televisión",
    generic_program_code: 126,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Realizador de Cine y Televisión",
    generic_program_code: 126,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "23",
    institution_name: "Universidad Adolfo Ibáñez",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Realizador de Cine y Televisión",
    generic_program_code: 126,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Geografía",
    generic_program_code: 38,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "896",
    institution_name: "Universidad de Aysén",
    region_name: "Aysén",
    region_id: 11,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "896",
    institution_name: "Universidad de Aysén",
    region_name: "Aysén",
    region_id: 11,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Administración de Empresas e Ing. Asociadas",
    generic_program_code: 5,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Realizador de Cine y Televisión",
    generic_program_code: 126,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Matemática y Estadística",
    generic_program_code: 48,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Biotecnología y Bioingeniería",
    generic_program_code: 72,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "23",
    institution_name: "Universidad Adolfo Ibáñez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Electrónica",
    generic_program_code: 45,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Metalúrgica",
    generic_program_code: 50,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Matemática y Estadística",
    generic_program_code: 48,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Industrias Forestales",
    generic_program_code: 56,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Matemática y Estadística",
    generic_program_code: 48,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Publicidad",
    generic_program_code: 121,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "896",
    institution_name: "Universidad de Aysén",
    region_name: "Aysén",
    region_id: 11,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Obras Civiles",
    generic_program_code: 59,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Diseño Gráfico",
    generic_program_code: 30,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Música, Canto o Danza",
    generic_program_code: 95,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "23",
    institution_name: "Universidad Adolfo Ibáñez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Telemática",
    generic_program_code: 61,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Civil en Obras Civiles",
    generic_program_code: 59,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Telemática",
    generic_program_code: 61,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Obras Civiles",
    generic_program_code: 59,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Obras Civiles",
    generic_program_code: 59,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "23",
    institution_name: "Universidad Adolfo Ibáñez",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "23",
    institution_name: "Universidad Adolfo Ibáñez",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Civil en Obras Civiles",
    generic_program_code: 59,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "38",
    institution_name: "Universidad Adventista de Chile",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Matemática y Estadística",
    generic_program_code: 80,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Agroindustrial",
    generic_program_code: 41,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Geomensura y Cartografía",
    generic_program_code: 79,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería en Química",
    generic_program_code: 85,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Química",
    generic_program_code: 85,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Electricidad",
    generic_program_code: 77,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Electrónica",
    generic_program_code: 78,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Medio Ambiente",
    generic_program_code: 81,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Matemática y Estadística",
    generic_program_code: 80,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería en Electricidad",
    generic_program_code: 77,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Electrónica",
    generic_program_code: 78,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Geomensura y Cartografía",
    generic_program_code: 79,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Electricidad",
    generic_program_code: 77,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Prevención de Riesgos",
    generic_program_code: 84,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Prevención de Riesgos",
    generic_program_code: 84,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Electricidad",
    generic_program_code: 77,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Metalurgia",
    generic_program_code: 82,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Minas",
    generic_program_code: 83,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Matemática y Estadística",
    generic_program_code: 80,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Recursos Renovables",
    generic_program_code: 87,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Medio Ambiente",
    generic_program_code: 81,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Metalurgia",
    generic_program_code: 82,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Prevención de Riesgos",
    generic_program_code: 84,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Medio Ambiente",
    generic_program_code: 81,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Recursos Renovables",
    generic_program_code: 87,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Recursos Renovables",
    generic_program_code: 87,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Medio Ambiente",
    generic_program_code: 81,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Matemática y Estadística",
    generic_program_code: 80,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería en Recursos Renovables",
    generic_program_code: 87,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Geomensura y Cartografía",
    generic_program_code: 79,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Recursos Renovables",
    generic_program_code: 87,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Medio Ambiente",
    generic_program_code: 81,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Publicidad",
    generic_program_code: 121,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Civil en Obras Civiles",
    generic_program_code: 59,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Medio Ambiente",
    generic_program_code: 81,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Música, Canto o Danza",
    generic_program_code: 95,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Ingeniería Civil Electrónica",
    generic_program_code: 45,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería Civil Electrónica",
    generic_program_code: 45,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Metalúrgica",
    generic_program_code: 50,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería Civil Ambiental",
    generic_program_code: 43,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Música, Canto o Danza",
    generic_program_code: 95,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil Ambiental",
    generic_program_code: 43,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Metalúrgica",
    generic_program_code: 50,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Química",
    generic_program_code: 51,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil Química",
    generic_program_code: 51,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Ambiental",
    generic_program_code: 43,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Bioquímica",
    generic_program_code: 44,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Química",
    generic_program_code: 51,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Química",
    generic_program_code: 51,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Ambiental",
    generic_program_code: 43,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Química",
    generic_program_code: 51,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Metalúrgica",
    generic_program_code: 50,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Química",
    generic_program_code: 51,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil Ambiental",
    generic_program_code: 43,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Electrónica",
    generic_program_code: 45,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Música, Canto o Danza",
    generic_program_code: 95,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Ingeniería Civil Ambiental",
    generic_program_code: 43,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Metalúrgica",
    generic_program_code: 50,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Química",
    generic_program_code: 51,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Ambiental",
    generic_program_code: 43,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Biotecnología y/o Bioingeniería",
    generic_program_code: 52,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Música, Canto o Danza",
    generic_program_code: 95,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil Electrónica",
    generic_program_code: 45,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería Civil Ambiental",
    generic_program_code: 43,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Electrónica",
    generic_program_code: 45,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil Química",
    generic_program_code: 51,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil Ambiental",
    generic_program_code: 43,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Administración Gastronómica",
    generic_program_code: 2,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Actuación y Teatro",
    generic_program_code: 1,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Actuación y Teatro",
    generic_program_code: 1,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Música, Canto o Danza",
    generic_program_code: 95,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Actuación y Teatro",
    generic_program_code: 1,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Administración Gastronómica",
    generic_program_code: 2,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Administración Turística y Hotelera",
    generic_program_code: 4,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Administración de Empresas e Ing. Asociadas",
    generic_program_code: 5,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Animación Digital",
    generic_program_code: 8,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración Turística y Hotelera",
    generic_program_code: 4,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Administración Turística y Hotelera",
    generic_program_code: 4,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Antropología",
    generic_program_code: 9,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Analista Químico",
    generic_program_code: 7,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Administración de Empresas e Ing. Asociadas",
    generic_program_code: 5,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Animación Digital",
    generic_program_code: 8,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración Turística y Hotelera",
    generic_program_code: 4,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Antropología",
    generic_program_code: 9,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración Turística y Hotelera",
    generic_program_code: 4,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Administración de Empresas e Ing. Asociadas",
    generic_program_code: 5,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Administración Turística y Hotelera",
    generic_program_code: 4,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Administración de Empresas e Ing. Asociadas",
    generic_program_code: 5,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Antropología",
    generic_program_code: 9,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración Turística y Hotelera",
    generic_program_code: 4,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Arqueología",
    generic_program_code: 10,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Antropología",
    generic_program_code: 9,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Antropología",
    generic_program_code: 9,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Animación Digital",
    generic_program_code: 8,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración de Empresas e Ing. Asociadas",
    generic_program_code: 5,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Administración de Empresas e Ing. Asociadas",
    generic_program_code: 5,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Antropología",
    generic_program_code: 9,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arqueología",
    generic_program_code: 10,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "896",
    institution_name: "Universidad de Aysén",
    region_name: "Aysén",
    region_id: 11,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Animación Digital",
    generic_program_code: 8,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Biología Marina y Ecología Marina",
    generic_program_code: 22,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Salud",
    generic_program_code: 18,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Bibliotecología",
    generic_program_code: 20,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Tecnología",
    generic_program_code: 19,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Humanidades",
    generic_program_code: 17,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Humanidades",
    generic_program_code: 17,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Biología Marina y Ecología Marina",
    generic_program_code: 22,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Salud",
    generic_program_code: 18,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Bioquímica",
    generic_program_code: 23,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Salud",
    generic_program_code: 18,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Salud",
    generic_program_code: 18,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Biología Marina y Ecología Marina",
    generic_program_code: 22,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Salud",
    generic_program_code: 18,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Bioquímica",
    generic_program_code: 23,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Música, Canto o Danza",
    generic_program_code: 95,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Actuación y Teatro",
    generic_program_code: 1,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Sociales",
    generic_program_code: 15,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Humanidades",
    generic_program_code: 17,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Bibliotecología",
    generic_program_code: 20,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Sociales",
    generic_program_code: 15,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Bioquímica",
    generic_program_code: 23,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bioquímica",
    generic_program_code: 23,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Biología",
    generic_program_code: 21,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bioquímica",
    generic_program_code: 23,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Biología Marina y Ecología Marina",
    generic_program_code: 22,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bioquímica",
    generic_program_code: 23,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Tecnología",
    generic_program_code: 19,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Tecnología",
    generic_program_code: 19,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Aysén",
    region_id: 11,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Aysén",
    region_id: 11,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Biología Marina y Ecología Marina",
    generic_program_code: 22,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Biología Marina y Ecología Marina",
    generic_program_code: 22,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Biología Marina y Ecología Marina",
    generic_program_code: 22,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bioquímica",
    generic_program_code: 23,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Salud",
    generic_program_code: 18,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Biología Marina y Ecología Marina",
    generic_program_code: 22,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Humanidades",
    generic_program_code: 17,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Ciencias Políticas",
    generic_program_code: 24,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Sociales",
    generic_program_code: 15,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ciencias Políticas",
    generic_program_code: 24,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Tecnología",
    generic_program_code: 19,
    institution_code: "23",
    institution_name: "Universidad Adolfo Ibáñez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Tecnología",
    generic_program_code: 19,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Salud",
    generic_program_code: 18,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Humanidades",
    generic_program_code: 17,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name:
      "Bachillerato y/o Licenciatura en Administración y Comercio",
    generic_program_code: 13,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ciencias Políticas",
    generic_program_code: 24,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Humanidades",
    generic_program_code: 17,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Ciencias Políticas",
    generic_program_code: 24,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Bibliotecología",
    generic_program_code: 20,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Ciencias Políticas",
    generic_program_code: 24,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ciencias Políticas",
    generic_program_code: 24,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Diseño Gráfico",
    generic_program_code: 30,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Comunicación Audiovisual y/o Multimedia",
    generic_program_code: 25,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Diseño Gráfico",
    generic_program_code: 30,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "23",
    institution_name: "Universidad Adolfo Ibáñez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "23",
    institution_name: "Universidad Adolfo Ibáñez",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Construcción Civil",
    generic_program_code: 26,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Comunicación Audiovisual y/o Multimedia",
    generic_program_code: 25,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Construcción Civil",
    generic_program_code: 26,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ciencias Políticas",
    generic_program_code: 24,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Construcción Civil",
    generic_program_code: 26,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Comunicación Audiovisual y/o Multimedia",
    generic_program_code: 25,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Diseño Gráfico",
    generic_program_code: 30,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Construcción Civil",
    generic_program_code: 26,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Diseño Industrial",
    generic_program_code: 31,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Actuación y Teatro",
    generic_program_code: 1,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Diseño de Vestuario",
    generic_program_code: 33,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Filosofía",
    generic_program_code: 35,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Filosofía",
    generic_program_code: 35,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Diseño Gráfico",
    generic_program_code: 30,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Diseño Industrial",
    generic_program_code: 31,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Filosofía",
    generic_program_code: 35,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Filosofía",
    generic_program_code: 35,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "896",
    institution_name: "Universidad de Aysén",
    region_name: "Aysén",
    region_id: 11,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Filosofía",
    generic_program_code: 35,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Diseño Teatral",
    generic_program_code: 32,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Filosofía",
    generic_program_code: 35,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Arica",
    region_id: 15,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Actuación y Teatro",
    generic_program_code: 1,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Aysén",
    region_id: 11,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Licenciatura en Letras y Literatura",
    generic_program_code: 91,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Licenciatura en Letras y Literatura",
    generic_program_code: 91,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Matemática y/o Estadística",
    generic_program_code: 92,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Licenciatura en Letras y Literatura",
    generic_program_code: 91,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Matemática y/o Estadística",
    generic_program_code: 92,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Licenciatura en Letras y Literatura",
    generic_program_code: 91,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Matemática y/o Estadística",
    generic_program_code: 92,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Licenciatura en Letras y Literatura",
    generic_program_code: 91,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Licenciatura en Letras y Literatura",
    generic_program_code: 91,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Licenciatura en Letras y Literatura",
    generic_program_code: 91,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Licenciatura en Letras y Literatura",
    generic_program_code: 91,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "2",
    institution_name: "Universidad Finis Terrae",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "82",
    institution_name: "Universidad Metropolitana de Ciencias de la Educación",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "81",
    institution_name: "Universidad Arturo Prat",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "34",
    institution_name: "Universidad de los Andes",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "72",
    institution_name: "Universidad de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "76",
    institution_name: "Universidad de la Frontera",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "84",
    institution_name: "Universidad de los Lagos",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Filosofía y Religión",
    generic_program_code: 113,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Derecho",
    generic_program_code: 16,
    institution_code: "45",
    institution_name: "Universidad del Desarrollo",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Filosofía",
    generic_program_code: 35,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Filosofía y Religión",
    generic_program_code: 113,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Artes y Música",
    generic_program_code: 107,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "69",
    institution_name: "Universidad Alberto Hurtado",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Artes y Música",
    generic_program_code: 107,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "39",
    institution_name: "Universidad San Sebastián",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Artes y Música",
    generic_program_code: 107,
    institution_code: "11",
    institution_name: "Universidad Academia de Humanismo Cristiano",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Otros Profesionales de Educación",
    generic_program_code: 104,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Industrial",
    generic_program_code: 65,
    institution_code: "85",
    institution_name: "Universidad Tecnológica Metropolitana",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Mecánica",
    generic_program_code: 67,
    institution_code: "80",
    institution_name: "Universidad de Tarapacá",
    region_name: "Arica",
    region_id: 15,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "4",
    institution_name: "Universidad Central de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "50",
    institution_name: "Universidad Bernardo O'Higgins",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Geomática y Geomensura",
    generic_program_code: 55,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería en Biotecnología y Bioingeniería",
    generic_program_code: 72,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Industrial",
    generic_program_code: 65,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Actuación y Teatro",
    generic_program_code: 1,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Diseño Teatral",
    generic_program_code: 32,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Música, Canto o Danza",
    generic_program_code: 95,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería Forestal",
    generic_program_code: 64,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería en Recursos Renovables",
    generic_program_code: 87,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Biología",
    generic_program_code: 21,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bioquímica",
    generic_program_code: 23,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería en Alimentos",
    generic_program_code: 70,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Matemática y/o Estadística",
    generic_program_code: 92,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Química Ambiental",
    generic_program_code: 122,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería en Biotecnología y Bioingeniería",
    generic_program_code: 72,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Antropología",
    generic_program_code: 9,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Filosofía",
    generic_program_code: 35,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Geografía",
    generic_program_code: 38,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Licenciatura en Letras y Literatura",
    generic_program_code: 91,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Realizador de Cine y Televisión",
    generic_program_code: 126,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería en Sonido",
    generic_program_code: 89,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Otros Profesionales de Ciencias Sociales",
    generic_program_code: 103,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ciencias Políticas",
    generic_program_code: 24,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Otras Ingenierías",
    generic_program_code: 99,
    institution_code: "70",
    institution_name: "Universidad de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Actuación y Teatro",
    generic_program_code: 1,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Licenciatura en Letras y Literatura",
    generic_program_code: 91,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Bioquímica",
    generic_program_code: 23,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Biología",
    generic_program_code: 21,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Biología Marina y Ecología Marina",
    generic_program_code: 22,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Humanidades",
    generic_program_code: 17,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Antropología",
    generic_program_code: 9,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Filosofía y Religión",
    generic_program_code: 113,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Mecánica",
    generic_program_code: 67,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Industrial",
    generic_program_code: 65,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Naval",
    generic_program_code: 68,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Mecánica",
    generic_program_code: 67,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Industrial",
    generic_program_code: 65,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Marina y Marítimo Portuaria",
    generic_program_code: 66,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Marina y Marítimo Portuaria",
    generic_program_code: 66,
    institution_code: "20",
    institution_name: "Universidad Andrés Bello",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Forestal",
    generic_program_code: 64,
    institution_code: "896",
    institution_name: "Universidad de Aysén",
    region_name: "Aysén",
    region_id: 11,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "1",
    institution_name: "Universidad Gabriela Mistral",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Básicas",
    generic_program_code: 14,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Ciencias Sociales",
    generic_program_code: 15,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Música, Canto o Danza",
    generic_program_code: 95,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Filosofía",
    generic_program_code: 35,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Geografía",
    generic_program_code: 38,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ciencias Políticas",
    generic_program_code: 24,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Construcción Civil",
    generic_program_code: 26,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Matemática y/o Estadística",
    generic_program_code: 92,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Química, Licenciado en Química",
    generic_program_code: 125,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Tecnología",
    generic_program_code: 19,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Recursos Renovables",
    generic_program_code: 87,
    institution_code: "86",
    institution_name: "Pontificia Universidad Católica de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Química, Licenciado en Química",
    generic_program_code: 125,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Matemática y/o Estadística",
    generic_program_code: 92,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería Civil Matemática y Estadística",
    generic_program_code: 48,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Analista Químico",
    generic_program_code: 7,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería Forestal",
    generic_program_code: 64,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería Civil Agrícola",
    generic_program_code: 42,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Ingeniería en Biotecnología y Bioingeniería",
    generic_program_code: 72,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Biología Marina y Ecología Marina",
    generic_program_code: 22,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Biología",
    generic_program_code: 21,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Geología",
    generic_program_code: 39,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Geografía",
    generic_program_code: 38,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Antropología",
    generic_program_code: 9,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Filosofía y Religión",
    generic_program_code: 113,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería en Recursos Renovables",
    generic_program_code: 87,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Medio Ambiente",
    generic_program_code: 81,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Bioquímica",
    generic_program_code: 23,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Humanidades",
    generic_program_code: 17,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Artes y Licenciatura en Artes",
    generic_program_code: 12,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Traducción e Interpretación",
    generic_program_code: 131,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería en Medio Ambiente",
    generic_program_code: 81,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Otras Ingenierías Civiles",
    generic_program_code: 100,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Materiales",
    generic_program_code: 57,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Matemática y Estadística",
    generic_program_code: 80,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Eléctrica",
    generic_program_code: 46,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Electrónica",
    generic_program_code: 45,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Metalúrgica",
    generic_program_code: 50,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Química",
    generic_program_code: 51,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Sociología",
    generic_program_code: 127,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "87",
    institution_name: "Universidad de Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Traducción e Interpretación",
    generic_program_code: 131,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Metalúrgica",
    generic_program_code: 50,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Electrónica",
    generic_program_code: 78,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Filosofía y Religión",
    generic_program_code: 113,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Artes y Música",
    generic_program_code: 107,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Civil en Obras Civiles",
    generic_program_code: 59,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Matemática y Estadística",
    generic_program_code: 48,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Otras Ingenierías Civiles",
    generic_program_code: 100,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "89",
    institution_name: "Pontificia Universidad Católica de Valparaíso",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Matemática y Estadística",
    generic_program_code: 48,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Matemática y Estadística",
    generic_program_code: 48,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Telemática",
    generic_program_code: 61,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Técnico en Mecánica Automotriz",
    generic_program_code: 138,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Técnico en Mantenimiento Industrial",
    generic_program_code: 137,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Técnico en Computación e Informática",
    generic_program_code: 132,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Industrial",
    generic_program_code: 65,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "88",
    institution_name: "Universidad Técnica Federico Santa María",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Geografía",
    generic_program_code: 54,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Obras Civiles",
    generic_program_code: 59,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Industrial",
    generic_program_code: 65,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Mecánica",
    generic_program_code: 67,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Química",
    generic_program_code: 85,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Refrigeración y Climatización",
    generic_program_code: 88,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Técnico en Química (Análisis e Industrial)",
    generic_program_code: 140,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Otras Ingenierías",
    generic_program_code: 99,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Química, Licenciado en Química",
    generic_program_code: 125,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Filosofía y Religión",
    generic_program_code: 113,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Historia",
    generic_program_code: 40,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Publicidad",
    generic_program_code: 121,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería en Alimentos",
    generic_program_code: 70,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Recursos Humanos",
    generic_program_code: 86,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Otros Profesionales de Educación",
    generic_program_code: 104,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Telemática",
    generic_program_code: 61,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Biotecnología y/o Bioingeniería",
    generic_program_code: 52,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Geomática y Geomensura",
    generic_program_code: 55,
    institution_code: "71",
    institution_name: "Universidad de Santiago de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Civil en Obras Civiles",
    generic_program_code: 59,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Sonido y Acústica",
    generic_program_code: 60,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "90",
    institution_name: "Universidad Austral de Chile",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Analista Químico",
    generic_program_code: 7,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "91",
    institution_name: "Universidad Católica del Norte",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "73",
    institution_name: "Universidad de Antofagasta",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Ambiental",
    generic_program_code: 43,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Alimentos",
    generic_program_code: 70,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Ingeniería Mecánica",
    generic_program_code: 67,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Minas",
    generic_program_code: 83,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Matemática y/o Estadística",
    generic_program_code: 92,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Ciencias",
    generic_program_code: 108,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Física y Astronomía",
    generic_program_code: 37,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Básicas",
    area_id: 4,
  },
  {
    generic_program_name: "Traducción e Interpretación",
    generic_program_code: 131,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "74",
    institution_name: "Universidad de la Serena",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Traducción e Interpretación",
    generic_program_code: 131,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "83",
    institution_name: "Universidad de Playa Ancha de Ciencias de la Educación",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Traducción e Interpretación",
    generic_program_code: 131,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Humanidades",
    area_id: 8,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "79",
    institution_name: "Universidad de Atacama",
    region_name: "Atacama",
    region_id: 3,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Diseño Industrial",
    generic_program_code: 31,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería en Computación e Informática",
    generic_program_code: 74,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Química y Farmacia",
    generic_program_code: 124,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Diseño Gráfico",
    generic_program_code: 30,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "75",
    institution_name: "Universidad del Bío-Bío",
    region_name: "Ñuble",
    region_id: 16,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Otras Ingenierías",
    generic_program_code: 99,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "77",
    institution_name: "Universidad de Magallanes",
    region_name: "Magallanes",
    region_id: 12,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Computación e Informática",
    generic_program_code: 53,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Obstetricia y Puericultura",
    generic_program_code: 97,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Odontología",
    generic_program_code: 98,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Otras Ingenierías Civiles",
    generic_program_code: 100,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil en Obras Civiles",
    generic_program_code: 59,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Civil Mecánica",
    generic_program_code: 49,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "78",
    institution_name: "Universidad de Talca",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "92",
    institution_name: "Universidad Católica del Maule",
    region_name: "Maule",
    region_id: 7,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "93",
    institution_name: "Universidad Católica de la Santísima Concepción",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Antropología",
    generic_program_code: 9,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Agronomía",
    generic_program_code: 6,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Administración Pública",
    generic_program_code: 3,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Arqueología",
    generic_program_code: 10,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Arquitectura",
    generic_program_code: 11,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name: "Diseño",
    generic_program_code: 29,
    institution_code: "94",
    institution_name: "Universidad Católica de Temuco",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Arte y Arquitectura",
    area_id: 3,
  },
  {
    generic_program_name:
      "Bachillerato y/o Licenciatura en Administración y Comercio",
    generic_program_code: 13,
    institution_code: "3",
    institution_name: "Universidad Diego Portales",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Forestal",
    generic_program_code: 64,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Salud",
    generic_program_code: 18,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina",
    generic_program_code: 93,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Bachillerato y/o Licenciatura en Salud",
    generic_program_code: 18,
    institution_code: "10",
    institution_name: "Universidad Mayor",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Pedagogía en Filosofía y Religión",
    generic_program_code: 113,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "42",
    institution_name: "Universidad Católica Cardenal Raúl Silva Henríquez",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Matemáticas y Computación",
    generic_program_code: 117,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Ingeniería Civil, plan común y licenciatura en Ciencias de la Ingeniería",
    generic_program_code: 62,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "895",
    institution_name: "Universidad de O'Higgins",
    region_name: "O'Higgins",
    region_id: 6,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "896",
    institution_name: "Universidad de Aysén",
    region_name: "Aysén",
    region_id: 11,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Periodismo",
    generic_program_code: 118,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería en Construcción",
    generic_program_code: 75,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Trabajo Social",
    generic_program_code: 130,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Pedagogía en Educación Básica",
    generic_program_code: 109,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Física",
    generic_program_code: 111,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación de Párvulos",
    generic_program_code: 112,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name:
      "Pedagogía en Historia, Geografía y Ciencias Sociales",
    generic_program_code: 114,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Idiomas",
    generic_program_code: 115,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Lenguaje, Comunicación y/o Castellano",
    generic_program_code: 116,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "31",
    institution_name: "Universidad Autónoma de Chile",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Otros Profesionales de Educación",
    generic_program_code: 104,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Maule",
    region_id: 7,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Enfermería",
    generic_program_code: 34,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Fonoaudiología",
    generic_program_code: 36,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Arica",
    region_id: 15,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Maule",
    region_id: 7,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Maule",
    region_id: 7,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Medicina Veterinaria",
    generic_program_code: 94,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Agropecuaria",
    area_id: 2,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Antofagasta",
    region_id: 2,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Arica",
    region_id: 15,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Maule",
    region_id: 7,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Valparaíso",
    region_id: 5,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Terapia Ocupacional",
    generic_program_code: 129,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Otros Profesionales de Tecnología",
    generic_program_code: 105,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Contador Auditor",
    generic_program_code: 27,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Coquimbo",
    region_id: 4,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Pedagogía en Educación Diferencial",
    generic_program_code: 110,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Ingeniería Civil en Minas",
    generic_program_code: 58,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Ingeniería en Control de Gestión",
    generic_program_code: 76,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Araucanía",
    region_id: 9,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Kinesiología",
    generic_program_code: 90,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Tarapacá",
    region_id: 1,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Nutrición y Dietética",
    generic_program_code: 96,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Tecnología Médica",
    generic_program_code: 128,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Salud",
    area_id: 9,
  },
  {
    generic_program_name: "Ingeniería Comercial",
    generic_program_code: 63,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Maule",
    region_id: 7,
    area_name: "Administración y Comercio",
    area_id: 1,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Lagos",
    region_id: 10,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Derecho",
    generic_program_code: 28,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Derecho",
    area_id: 6,
  },
  {
    generic_program_name: "Otros Profesionales de Educación",
    generic_program_code: 104,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Maule",
    region_id: 7,
    area_name: "Educación",
    area_id: 7,
  },
  {
    generic_program_name: "Psicología",
    generic_program_code: 119,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Los Ríos",
    region_id: 14,
    area_name: "Ciencias Sociales",
    area_id: 5,
  },
  {
    generic_program_name: "Ingeniería Civil Industrial",
    generic_program_code: 47,
    institution_code: "13",
    institution_name: "Universidad Santo Tomás",
    region_name: "Bio-Bío",
    region_id: 8,
    area_name: "Tecnología",
    area_id: 10,
  },
  {
    generic_program_name: "Otros Profesionales de Educación",
    generic_program_code: 104,
    institution_code: "19",
    institution_name: "Universidad de las Américas",
    region_name: "Metropolitana",
    region_id: 13,
    area_name: "Educación",
    area_id: 7,
  },
];
