import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import vuetify from "./plugins/vuetify";

import "./styles/main.scss";
import Base from "@/components/modules/Base.vue";
// vimeo
import vueVimeoPlayer from "vue-vimeo-player";
Vue.use(vueVimeoPlayer);
// smooth scroll
import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll);

// mixpanel
import mixpanel from "mixpanel-browser";
// initialize mixpanel
if (window.location.pathname.includes("sae")) {
  mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN_SAE, {
    debug: process.env.VUE_APP_MIXPANEL_DEBUG || false,
    ignore_dnt: true,
  });
} else {
  mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
    debug: process.env.VUE_APP_MIXPANEL_DEBUG || false,
    ignore_dnt: true,
  });
}

// papaparse
import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);

// cellhpone input
Vue.use(VueTelInputVuetify, {
  vuetify,
});

// common components
// sae
import SaeButton from "./components/SaeButton.vue";
import SaeCard from "./components/SaeCard.vue";
import SaeInput from "./components/SaeInput.vue";
// edusup
import CommonList from "@/components/common/CommonList.vue";
import CommonTimeline from "@/components/common/CommonTimeline.vue";
import CommonText from "@/components/common/CommonText.vue";
import CommonBox from "@/components/common/CommonBox.vue";
import CommonButton from "@/components/common/CommonButton.vue";
import CommonInformation from "@/components/common/CommonInformation.vue";
import CommonPanelHeader from "@/components/common/CommonPanelHeader.vue";
import CommonDiv from "@/components/common/CommonDiv.vue";
import CommonScore from "@/components/common/CommonScore.vue";

Vue.config.productionTip = false;
// edusup
Vue.component("SaeButton", SaeButton);
Vue.component("SaeCard", SaeCard);
Vue.component("SaeInput", SaeInput);
// sae
Vue.component("base-module", Base);
Vue.component("common-list", CommonList);
Vue.component("common-timeline", CommonTimeline);
Vue.component("common-text", CommonText);
Vue.component("common-box", CommonBox);
Vue.component("common-button", CommonButton);
Vue.component("common-information", CommonInformation);
Vue.component("common-panel-header", CommonPanelHeader);
Vue.component("common-div", CommonDiv);
Vue.component("common-score", CommonScore);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
