<template>
  <v-card
    elevation="0"
    class="mb-4 pa-4"
    rounded
    :style="{ backgroundColor: backgroundColor }"
  >
    <div class="d-flex">
      <div class="bold" :style="{ color: titleColor }" v-if="title != ''">
        {{ title }}
      </div>
      <v-menu v-model="menu" :close-on-content-click="true" offset-x offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ms-auto"
            style="margin-top: -10px"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :color="titleColor">mdi-help-circle-outline</v-icon>
          </v-btn>
        </template>

        <v-card
          min-width="300"
          max-width="300"
          width="300"
          style="background: #cfdffe"
        >
          <div class="pa-2">
            <div class="text-left d-flex">
              <div class="pt-2 ps-2 bold" style="color: #09317c">
                {{ title }}
              </div>
              <v-btn
                class="ms-auto"
                icon
                color=" #09317C"
                @click="menu = !menu"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div class="text-left pa-2 ps-4" style="color: #09317c">
              <common-text :text="tooltip" v-if="tooltip" />
              <slot name="tooltip"></slot>
            </div>
          </div>
        </v-card>
      </v-menu>
      <!--<v-tooltip top v-if="tooltip != ''">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="titleColor"
            class="ms-auto"
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-help-circle-outline
          </v-icon>
        </template>
        <span> <common-text :text="tooltip" /></span>
      </v-tooltip>-->
    </div>
    <div :style="{ color: textColor }"><slot></slot></div>
  </v-card>
</template>
<script>
export default {
  name: "CommonInformation",
  data() {
    return {
      menu: false,
    };
  },
  props: {
    title: {
      required: false,
      default: "",
      type: String,
    },
    tooltip: {
      required: false,
      type: String,
      default: "",
    },
    titleColor: {
      required: false,
      default: "#1161f8",
      type: String,
    },
    textColor: {
      required: false,
      default: "#6096FA",
      type: String,
    },
    backgroundColor: {
      required: false,
      default: "white",
      type: String,
    },
  },
};
</script>
