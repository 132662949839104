<template>
  <svg
    width="27"
    height="13"
    viewBox="0 0 27 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="27" height="12.75" rx="6.375" fill="#99F6E4" />
    <path
      d="M7.236 9.438C6.816 9.438 6.438 9.366 6.102 9.222C5.766 9.072 5.502 8.862 5.31 8.592C5.118 8.322 5.022 8.007 5.022 7.647H6.12C6.144 7.917 6.249 8.139 6.435 8.313C6.627 8.487 6.894 8.574 7.236 8.574C7.59 8.574 7.866 8.49 8.064 8.322C8.262 8.148 8.361 7.926 8.361 7.656C8.361 7.446 8.298 7.275 8.172 7.143C8.052 7.011 7.899 6.909 7.713 6.837C7.533 6.765 7.281 6.687 6.957 6.603C6.549 6.495 6.216 6.387 5.958 6.279C5.706 6.165 5.49 5.991 5.31 5.757C5.13 5.523 5.04 5.211 5.04 4.821C5.04 4.461 5.13 4.146 5.31 3.876C5.49 3.606 5.742 3.399 6.066 3.255C6.39 3.111 6.765 3.039 7.191 3.039C7.797 3.039 8.292 3.192 8.676 3.498C9.066 3.798 9.282 4.212 9.324 4.74H8.19C8.172 4.512 8.064 4.317 7.866 4.155C7.668 3.993 7.407 3.912 7.083 3.912C6.789 3.912 6.549 3.987 6.363 4.137C6.177 4.287 6.084 4.503 6.084 4.785C6.084 4.977 6.141 5.136 6.255 5.262C6.375 5.382 6.525 5.478 6.705 5.55C6.885 5.622 7.131 5.7 7.443 5.784C7.857 5.898 8.193 6.012 8.451 6.126C8.715 6.24 8.937 6.417 9.117 6.657C9.303 6.891 9.396 7.206 9.396 7.602C9.396 7.92 9.309 8.22 9.135 8.502C8.967 8.784 8.718 9.012 8.388 9.186C8.064 9.354 7.68 9.438 7.236 9.438ZM12.3826 3.948V5.784H14.5426V6.621H12.3826V8.538H14.8126V9.375H11.3566V3.111H14.8126V3.948H12.3826ZM21.2004 4.983C21.2004 5.301 21.1254 5.601 20.9754 5.883C20.8254 6.165 20.5854 6.396 20.2554 6.576C19.9254 6.75 19.5024 6.837 18.9864 6.837H17.8524V9.375H16.8264V3.12H18.9864C19.4664 3.12 19.8714 3.204 20.2014 3.372C20.5374 3.534 20.7864 3.756 20.9484 4.038C21.1164 4.32 21.2004 4.635 21.2004 4.983ZM18.9864 6C19.3764 6 19.6674 5.913 19.8594 5.739C20.0514 5.559 20.1474 5.307 20.1474 4.983C20.1474 4.299 19.7604 3.957 18.9864 3.957H17.8524V6H18.9864Z"
      fill="#3662E3"
    />
  </svg>
</template>
<script>
export default {
  name: "Sep.svg",
};
</script>
