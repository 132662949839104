<template>
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_186_8198"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="23"
      height="24"
    >
      <rect y="0.5" width="23" height="23" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_186_8198)">
      <path
        d="M5.75033 20.625C4.69616 20.625 3.79373 20.2497 3.04303 19.499C2.29234 18.7483 1.91699 17.8458 1.91699 16.7917C1.91699 15.7375 2.29234 14.8351 3.04303 14.0844C3.79373 13.3337 4.69616 12.9583 5.75033 12.9583C6.80449 12.9583 7.70692 13.3337 8.45762 14.0844C9.20831 14.8351 9.58366 15.7375 9.58366 16.7917C9.58366 17.8458 9.20831 18.7483 8.45762 19.499C7.70692 20.2497 6.80449 20.625 5.75033 20.625ZM17.2503 20.625C16.1962 20.625 15.2937 20.2497 14.543 19.499C13.7923 18.7483 13.417 17.8458 13.417 16.7917C13.417 15.7375 13.7923 14.8351 14.543 14.0844C15.2937 13.3337 16.1962 12.9583 17.2503 12.9583C18.3045 12.9583 19.2069 13.3337 19.9576 14.0844C20.7083 14.8351 21.0837 15.7375 21.0837 16.7917C21.0837 17.8458 20.7083 18.7483 19.9576 19.499C19.2069 20.2497 18.3045 20.625 17.2503 20.625ZM11.5003 11.0417C10.4462 11.0417 9.54373 10.6663 8.79303 9.91563C8.04234 9.16493 7.66699 8.2625 7.66699 7.20833C7.66699 6.15417 8.04234 5.25174 8.79303 4.50104C9.54373 3.75035 10.4462 3.375 11.5003 3.375C12.5545 3.375 13.4569 3.75035 14.2076 4.50104C14.9583 5.25174 15.3337 6.15417 15.3337 7.20833C15.3337 8.2625 14.9583 9.16493 14.2076 9.91563C13.4569 10.6663 12.5545 11.0417 11.5003 11.0417Z"
        fill="#66D6E7"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Circles.svg",
};
</script>
