var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.mobile ? '' : 'ms-10 me-10',staticStyle:{"height":"100vh","max-height":"100vh","box-sizing":"border-box"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","height":"100%","width":"100%"}},[_vm._t("icons")],2)]),_c('div',{staticClass:"pa-6 w-100",staticStyle:{"position":"relative","z-index":"5"},style:(_vm.mobile ? 'height:10vh' : 'height:15vh')},[(_vm.screens && _vm.screens.length > 0)?_c('v-icon',{staticClass:"mt-4",staticStyle:{"position":"absolute","z-index":"5"},attrs:{"color":"#3662E3"},on:{"click":function($event){return _vm.$emit('goBack')}}},[_vm._v("mdi-arrow-left")]):_vm._e(),_c('div',{staticClass:"w-100",class:_vm.leftLogo ? 'text-left' : 'text-center'},[_c('img',{class:_vm.leftLogo
            ? _vm.mobile
              ? 'me-auto'
              : 'ms-8 mt-8 me-auto'
            : 'ms-auto me-auto',staticStyle:{"z-index":"5","height":"60px"},attrs:{"src":_vm.whiteLogo
            ? require('@/assets/AdmisionEscolar.png')
            : require('@/assets/AdmisionEscolarBlue.png')}})])],1),_c('div',{staticClass:"d-flex pa-6",class:_vm.mobile ? 'flex-column' : 'flex-row',staticStyle:{"height":"80vh"},style:(_vm.mobile ? 'height: 80vh; gap: 10px' : 'height: 75vh; gap: 20px')},[_vm._t("content")],2),_c('div',{staticClass:"pa-6",staticStyle:{"height":"10vh"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }