<template>
  <div>
    <v-list>
      <v-list-item v-for="(item, i) in items" :key="i">
        <v-list-item-icon>
          <v-icon :color="iconColor"> {{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            ><common-text class="text-wrap" :text="item.text"
          /></v-list-item-title>
        </v-list-item-content> </v-list-item
    ></v-list>
  </div>
</template>
<script>
export default {
  name: "CommonList",
  props: {
    small: {
      required: false,
      default: false,
      type: Boolean,
    },
    icon: {
      required: false,
      default: null,
    },
    iconColor: {
      required: false,
      default: "",
      type: String,
    },
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
};
</script>
