<template>
  <base-module v-bind="$props">
    <v-icon x-large color="#FF0C4F" v-if="icon && iconPosition == 'top'">{{
      icon
    }}</v-icon>

    <div v-if="title != ''" class="text-title bold">
      {{ title }}
      <v-icon x-large color="#FF0C4F" v-if="icon && iconPosition == 'after'">{{
        icon
      }}</v-icon>
    </div>
    <slot></slot>
  </base-module>
</template>
<script>
export default {
  name: "Alert",
  props: {
    startColor: {},
    endColor: {},
    icon: {
      required: false,
      default: null,
    },
    title: {
      required: true,
      type: String,
    },
    iconPosition: {
      required: false,
      default: "top",
      type: String,
    },
  },
};
</script>
