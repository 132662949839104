<template>
  <p style="padding: 0px; margin: 0px" v-html="processedText"></p>
</template>

<script>
export default {
  name: "CommonText",
  props: {
    text: {},
    size: {},
    color: {},
    ignoreStyles: {},
  },
  watch: {
    text: function (newVal) {
      // watch it
      this.processedText = newVal;
      this.processText();
    },
  },

  data() {
    return {
      processedText: this.text,
    };
  },
  methods: {
    processText() {
      this.newText = this.processedText;

      // size
      if (this.ignoreStyles) {
        this.processedText = this.processedText.replace(
          /\\big\{([ñ\d.:?!¿¡\\wóéíúá ÁÉÍÓÚ/,()%]+?)\}/g,
          function (a, b) {
            return b;
          }
        );
      } else {
        this.processedText = this.processedText.replace(
          /\\big\{([ñ\\\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()%]*?)\}/g,
          function (a, b) {
            return "<span style='font-size: 200%'>" + b + "</span>";
          }
        );
      }
      // link
      if (this.ignoreStyles) {
        this.processedText = this.processedText.replace(
          /\\link\{([ñ\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()%{}<>=\-"$\\]+?), ([\w]+?)\}/g,
          function (a, link, text) {
            return text;
          }
        );
      } else {
        this.processedText = this.processedText.replace(
          /\\link\{([ñ\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()%{}<>=\-"$\\]+?), ([\w]+?)\}/g,
          function (a, link, text) {
            return "<a href='" + link + "' target = '_blank'>" + text + "</a>";
          }
        );
      }
      // bold
      this.processedText = this.processedText.replace(
        /\\bold\{(.*?)\}/g,
        function (a, b) {
          return "<span style='font-weight:bold'>" + b + "</span>";
        }
      );

      // italic
      if (this.ignoreStyles) {
        this.processedText = this.processedText.replace(
          /\\italic\{(.*?)\}/g,
          function (a, b) {
            return b;
          }
        );
      } else {
        this.processedText = this.processedText.replace(
          /\\italic\{(.*?)\}/g,
          function (a, b) {
            return "<i>" + b + "</i>";
          }
        );
      }
      // icon
      // ADD color
      if (!this.ignoreStyles) {
        this.processedText = this.processedText.replace(
          /\\icon\{(.*?)\}/g,
          function (a, b) {
            return "<span class='color--primary mdi " + b + "'></span>";
          }
        );
      } else {
        this.processedText = this.processedText.replace(
          /\\icon\{(.*?)\}/g,
          function (a, b) {
            return b;
          }
        );
      }

      // underline
      if (this.ignoreStyles) {
        this.processedText = this.processedText.replace(
          /\\underline\{(.*?)\}/g,
          function (a, b) {
            return b;
          }
        );
      } else {
        this.processedText = this.processedText.replace(
          /\\underline\{(.*?)\}/g,
          function (a, b) {
            return "<span style='text-decoration:underline'>" + b + "</span>";
          }
        );
      }

      // special underline
      if (this.ignoreStyles) {
        this.processedText = this.processedText.replace(
          /\\specialunderline\{([\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/]+?), ([#\w]+?), ([#\w]+?)\}/g,
          // eslint-disable-next-line
          function (a, text, highlightColor, textColor) {
            return text;
          }
        );
      } else {
        this.processedText = this.processedText.replace(
          /\\specialunderline\{([\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/]+?), ([#\w]+?), ([#\w]+?)\}/g,
          function (a, text, highlightColor, textColor) {
            return (
              "<span style='background: linear-gradient(to top, " +
              highlightColor +
              " 50%, transparent 50%)" +
              ";color:" +
              textColor +
              "'>" +
              text +
              "</span>"
            );
          }
        );
      }

      // higlight
      if (this.ignoreStyles) {
        this.processedText = this.processedText.replace(
          /\\highlight\{([ñ\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()%{}<>=\-"$\\]+?), ([#\w]+?), ([#\w]+?)\}/g,
          function (a, text) {
            return text;
          }
        );
      } else {
        this.processedText = this.processedText.replace(
          /\\highlight\{([ñ\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()%{}<>=\-"$\\]+?), ([#\w]+?), ([#\w]+?)\}/g,
          function (a, text, highlightColor, textColor) {
            var t =
              "<span class='text' style='color:" +
              textColor +
              ";background:" +
              highlightColor +
              ";box-shadow:3px 0 0 " +
              highlightColor +
              ", -3px 0 0 " +
              highlightColor +
              ";padding: 5px 3px;'>" +
              text +
              "</span>";
            return t;
          }
        );
      }

      // boldcolor
      if (this.ignoreStyles) {
        this.processedText = this.processedText.replace(
          /\\boldcolor\{([<>\\#\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()]+?), ([#\w]+?)\}/g,
          // eslint-disable-next-line
          function (a, text, textColor) {
            return text;
          }
        );
      } else {
        this.processedText = this.processedText.replace(
          /\\boldcolor\{([\\<>ñ\d.:'?!¿¡\wóéíúá ÁÉÍÓÚ/=\-,()%]+?), ([#\w]+?)\}/g,
          function (a, text, textColor) {
            return (
              "<span style='font-weight:bold;color:" +
              textColor +
              ";'>" +
              text +
              "</span>"
            );
          }
        );
      }

      // link
      if (this.ignoreStyles) {
        this.processedText = this.processedText.replace(
          /\\link\{([<>\\#\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()]+?), ([#\w]+?)\}/g,
          // eslint-disable-next-line
          function (a, text, link) {
            return text;
          }
        );
      } else {
        this.processedText = this.processedText.replace(
          /\\link\{([\\<>ñ\d.:'?!¿¡\wóéíúá ÁÉÍÓÚ/=\-,()%]+?), ([\w./ ]+?)\}/g,
          function (a, text, link) {
            return "<a href='" + link + "'>" + text + "</a>";
          }
        );
      }

      // newline
      this.processedText = this.processedText.replace(/\\n/g, "<br/>");
    },
  },
  mounted() {
    this.processText();
  },
};
</script>
