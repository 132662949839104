var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',_vm._b({staticClass:"text-none flex-fill",class:[
        _vm.primary
          ? 'bg--sae-primary color--neutral-100-t'
          : 'bg--neutral-100-t color--sae-primary',
        _vm.buttonClass,
      ].join(' '),staticStyle:{"letter-spacing":"0.5px !important"},style:({
      background: _vm.disabled ? 'lightgray !important' : '',
      ..._vm.buttonStyle,
    }),attrs:{"rounded":_vm.rounded,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},'v-btn',{ ..._vm.$attrs },false),[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }