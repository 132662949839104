<template>
  <div style="position: relative">
    <div
      :style="{ background: color, opacity: opacity }"
      class="rounded"
      style="position: absolute; top: 0; width: 100%; height: 100%; z-index: 1"
    ></div>
    <div
      style="z-index: 2; position: relative"
      class="pa-4 rounded"
      :class="inline ? 'd-flex' : ''"
    >
      <v-icon :class="inline ? 'me-4' : ''" large :color="iconColor">{{
        icon
      }}</v-icon>
      <div>
        <slot> </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CommonBox",
  props: {
    opacity: {
      required: false,
      default: 0.3,
      type: Number,
    },
    color: {
      required: false,
      default: "white",
      type: String,
    },
    icon: {
      required: false,
      type: String,
      default: null,
    },
    inline: {
      required: false,
      type: Boolean,
      default: false,
    },
    iconColor: {
      required: true,
      type: String,
      default: "transparent",
    },
  },
};
</script>
