<template>
  <v-text-field
    filled
    style="background: white !important"
    class="text-body bg--neutral-100-t mb-2"
    dense
    :hide-details="!showDetails"
    v-bind="$attrs"
    v-model="computedValue"
  ></v-text-field>
</template>
<script>
export default {
  name: "SaeInput",
  props: {
    showDetails: {
      required: false,
      default: false,
      type: Boolean,
    },
    value: {
      required: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
<style>
.v-input > .v-input__control > .v-input__slot {
  background: white !important;
}
.v-input > .v-input__control > .v-input__slot > .v-text-field__slot {
  background: white !important;
}
</style>
