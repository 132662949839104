<template>
  <div :ref="reference">
    <slot></slot>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "CommonPanelHeader",
  mounted() {
    window.addEventListener("popstate", () => {
      history.pushState("", document.title, window.location.pathname);
    });
  },
  props: {
    panelData: {
      /* extra information to be tracked */
      required: false,
      type: Object,
      default: () => ({}),
    },
    selected: {
      /* wether the current panel is open or not */

      required: true,
      type: Boolean,
    },
    reference: {
      /* name of the panel */
      required: true,
      type: String,
    },
    offset: {
      /* vertical offset upon opening panel */
      required: false,
      type: Number,
      default: 30,
    },
  },
  watch: {
    selected(newVal) {
      if (newVal) {
        this.track({
          event: `panel-open-${this.reference}`,
          data: this.panelData,
        });
        this.smoothScroll(10);
      } else {
        this.track({
          event: `panel-close-${this.reference}`,
          data: this.panelData,
        });
      }
    },
  },
  methods: {
    ...mapActions({
      track: "track",
    }),
    onClick() {
      this.$nextTick(() => {
        history.pushState("", document.title, window.location.pathname);
      });
    },
    smoothScroll(n) {
      if (n > 0) {
        this.$smoothScroll({
          scrollTo: this.$refs[this.reference],
          duration: 30,
          offset: -this.offset,
        });
        setTimeout(() => {
          this.smoothScroll(n - 1);
        }, 30);
      }
    },
  },
};
</script>
