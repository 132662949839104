<template>
  <div>
    <div v-if="loaded && isEnabled">
      <welcome
        :name="student.name"
        description="A continuación te entregaremos información para que puedas tomar una decisión informada con respecto a tu paso a la Educación Superior."
        start-color="#F4F8FF"
        end-color="#F4F8FF"
      />
      <all-scores
        :scores="student.scores"
        description="Esta es la \bold{combinación de los mejores puntajes} que tienes hasta ahora para  postular en el proceso de admisión a la educación superior 2024.\nRecuerda que dichos puntajes están en la nueva escala de la prueba PAES."
        start-color="#F4F8FF"
        :end-color="hasAll ? '#FFE1E1' : '#FFF3F6'"
      />
      <!-- new -->
      <resources
        start-color="#FFF3F6"
        end-color="#FFFEFE"
        v-if="!hasAll"
        subtitle=""
        title="Revisa la lista de carreras 2024 y el material de preparación para la PAES en:"
        :buttons="[
          { text: 'Acceso MINEDUC', link: 'https://acceso.mineduc.cl/' },
        ]"
        hasSun
      />
      <alert
        v-if="hasAll"
        icon="$shuriken"
        icon-position="after"
        title="¡Aún tienes tiempo para preparar la PAES 2023!"
        start-color="#FFE1E1"
        end-color="white"
      >
        <common-text
          class="text-subtitle"
          text="Recuerda que puedes postular con tu \bold{mejor combinación de puntajes vigentes}, por lo que si preparas la PAES podrías aumentar tus puntajes y así tus posibilidades de ingresar a la carrera que quieras."
        />
      </alert>
      <careers
        :careers="preferences"
        v-if="hasAll && preferences.length > 0"
        start-color="white"
        end-color="#FFF2F5"
      />
      <searcher
        :start-color="preferences.length > 0 ? '#FFF2F6' : 'white'"
        end-color="#CFDFFE"
        :scores="student.average_scores"
        v-if="hasAll"
      />
      <resources start-color="#CFDFFE" end-color="white" v-if="hasAll" />
      <dates
        title="Ten en cuenta algunas fechas importantes del proceso:"
        :dates="dates"
        start-color="white"
        end-color="#FFF2F5"
      />
    </div>
    <div v-else>
      <loader :disabled="!isEnabled" :error="error" />
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import Welcome from "@/components/modules/Welcome.vue";
import AllScores from "@/components/modules/AllScores.vue";
import Dates from "@/components/modules/Dates.vue";
import Careers from "@/components/modules/Careers.vue";
import Resources from "@/components/modules/Resources.vue";
import Alert from "@/components/modules/Alert.vue";
import Searcher from "@/components/modules/Searcher.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Unregistered.vue",
  components: {
    Loader,
    Welcome,
    AllScores,
    Dates,
    Careers,
    Resources,
    Alert,
    Searcher,
  },
  mounted() {
    let identifier = this.$route.params.id;
    this.setPrimer({ primer: "unregistered" });
    this.getStudent({ identifier, number: 3 }).then(() => {
      this.loaded = true;
    });
  },
  data() {
    return {
      loaded: false,

      dates: [
        {
          title: "Inscripción para rendir la PAES Regular",
          subtitle: "Hasta 26 de julio a las 13:00 horas",
        },
        {
          title:
            "Completar el Formulario de Acreditación Socioeconómico (FUAS)",
          subtitle: "Octubre",
        },
        {
          title: "Rendición PAES Regular",
          subtitle: "27, 28 y 29 de noviembre",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      student: "student",
    }),
    preferences() {
      if (this.student) {
        let preferences = this.student.preferences;
        preferences.sort((a, b) => a.order - b.order);
        return preferences;
      }
      return [];
    },
    isEnabled() {
      if (this.student) {
        return (
          this.student.treatments.filter(
            (treatment) => treatment.treatment.intervention_label === 3
          ).length > 0
        );
      }
      return true;
    },

    hasAll() {
      if (!this.student) {
        return false;
      }
      let hasAll = this.student.treatments.filter(
        (treatment) =>
          treatment.treatment.intervention_label_name ==
          "Feedback No Matriculados"
      );
      hasAll = hasAll.filter(
        (treatment) => treatment.treatment.sections.length > 4
      );
      return hasAll.length > 0;
    },
  },
  methods: {
    ...mapActions({
      getStudent: "getStudent",
      setPrimer: "setPrimer",
    }),
  },
};
</script>
