<template>
  <div
    :style="{
      'background-image': `linear-gradient(${startColor}, ${endColor})`,
    }"
  >
    <v-container class="section text-left">
      <v-row>
        <v-col cols="1" class="d-none d-lg-block"></v-col>
        <v-col :cols="$vuetify.breakpoint.name == 'lg' ? '10' : '12'">
          <div>
            <slot> </slot>
          </div>
        </v-col>
        <v-col cols="1" class="d-none d-lg-block"></v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Base",
  props: {
    startColor: {
      required: false,
      default: "white",
      type: String,
    },
    endColor: {
      required: false,
      default: "white",
      type: String,
    },
  },
};
</script>
