<template>
  <v-expansion-panel
    class="mt-4 rounded bg--primary-90-t"
    style="background: #f4f8ff"
  >
    <v-expansion-panel-header>
      <common-panel-header
        class="d-flex flex-column text-left"
        reference="career"
        :panelData="{
          careerName: career.name,
          careerInstitution: institutionName,
          careerCode: career.code,
          source,
        }"
        :selected="selected"
      >
        <div>
          <b>{{ career.name }}</b>
        </div>
        <div class="mt-1">
          {{ institutionName }}
        </div>
      </common-panel-header>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div
        class="mt-2 mb-2 bg--neutral-100-t pa-2 text-center rounded text-subtitle"
      >
        <div class="w-100 text-left">
          <v-icon large class="ms-1 mr-auto" color="#1DB396"
            >mdi-note-text-outline</v-icon
          >
        </div>

        <div class="text-left text-body ms-2 color--tertiary-20-s mb-2">
          Estas son las pruebas que este programa requiere para postular.
        </div>
        <div>
          <v-divider> </v-divider>
          <div
            class="d-flex text-center text-body w-100 justify-content-center mt-2 mb-2"
          >
            <div class="mr-2 ms-auto">
              <v-icon color="#48DEC0">mdi-square-rounded</v-icon>
              Pruebas requeridas
            </div>
            <div class="me-auto">
              <v-icon color="#EAEAEB">mdi-square-rounded</v-icon>
              Pruebas no requeridas
            </div>
          </div>

          <v-divider></v-divider>
        </div>
        <div
          class="pa-2 ps-4 ma-1 rounded text-left text-body"
          :class="
            career.tests.includes(test)
              ? 'bg--tertiary-50-t bold'
              : 'bg--neutral-90-t color--neutral-70-t'
          "
          v-for="(test, index) in [
            'Competencia Lectora',
            'Competencia Matemática (M1)',
            'Competencia Matemática (M2)',
          ]"
          :key="index"
        >
          • {{ test }}
        </div>
        <div
          v-if="hasBoth"
          class="pa-2 ps-4 ma-1 rounded text-left text-body bg--tertiary-50-t bold"
        >
          • Historia y Ciencias Sociales o Ciencias
        </div>
        <div v-else>
          <div
            class="pa-2 ps-4 ma-1 rounded text-left text-body"
            :class="
              career.tests.includes(test)
                ? 'bg--tertiary-50-t bold'
                : 'bg--neutral-90-t color--neutral-70-t'
            "
            v-for="(test, index) in [
              'Historia y Ciencias Sociales',
              'Ciencias',
            ]"
            :key="index"
          >
            • {{ test }}
          </div>
        </div>
        <!--<div class="text-body color--neutral-40-t text-left">
          Nota: El listado de carreras que exigen la M2 es aún preliminar.
          Confirma esta información con el programa.
        </div>
        <div v-if="hasSpecial" class="text-body color--neutral-40-t text-left">
          Nota 2: Este programa podría requerir una prueba especial. Confirma
          directamente con la institución que lo imparte.
        </div>-->
      </div>
      <!--<weighted-score
        :regular-score="regularScore"
        :pace-score="paceScore"
        :show-score-information="showScoreInformation"
        :status="status"
        :is_full_pace="is_full_pace"
        :program="career"
      />-->
      <!--<last-score
        v-if="![12023, 39399, '12023', '39399'].includes(career.code)"
        :pace="false"
        :bea="false"
        :is_full_pace="false"
        :is_full_bea="false"
        :is_full_reg="is_full_reg"
        :last_score_pace="false"
        :last_score_bea="false"
        :last_score_reg="last_score"
      />-->
      <div
        v-if="showScoreInformation"
        class="d-flex text-left flex-row justify-contents-center align-items-center mt-2 mb-2"
      >
        <v-icon class="me-2" color="#1161F8" x-large
          >mdi-note-text-outline</v-icon
        >
        <div class="bold mt-2">Información general</div>
      </div>
      <common-information title="Acreditación institucional">
        <div v-if="accreditation === null">Sin información.</div>
        <div v-else-if="accreditation == 0">
          <span
            v-if="
              [895, 896, '895', '896'].includes(career.campus.institution.code)
            "
            >Bajo tutela</span
          >
          <span v-else>Institución no acreditada.</span>
          <span></span>
        </div>
        <div v-else>
          <v-icon color="#48DEC0" class="me-2"
            >mdi-check-decagram-outline</v-icon
          >
          <div class="d-inline-block">
            {{ institutionName }}
          </div>
          acreditada por {{ accreditation }} años.
        </div>
        <template v-slot:tooltip>
          Para conocer la acreditación de las instituciones revisa en la
          Comisión Nacional de Acreditación (CNA),
          <span
            class="color--primary bold"
            style="cursor: pointer"
            @click="
              openLink({
                from: 'career',
                link: 'https://www.cnachile.cl/Paginas/preguntasfrecuentes.aspx',
              })
            "
            >haz click aquí</span
          >.
        </template>
      </common-information>
      <common-information title="Gratuidad">
        {{
          benefits == true
            ? "Esta carrera es elegible para gratuidad."
            : benefits == false
            ? "Esta carrera no es elegible para gratuidad."
            : "Sin información."
        }}
        <template v-slot:tooltip>
          Para conocer las instituciones que cuentan con gratuidad,
          <span
            class="color--primary bold"
            style="cursor: pointer"
            @click="
              openLink({
                from: 'career',
                link: 'https://portal.beneficiosestudiantiles.cl/gratuidad#references_tabs_field_asociar_subproceso_tab2',
              })
            "
            >haz click aquí</span
          >.
        </template>
      </common-information>
      <common-information
        title="Duración oficial"
        tooltip="La duración oficial es la duración de la carrera por malla curricular."
      >
        <div v-if="duration">{{ duration }} semestres.</div>
        <div v-else>Sin información.</div></common-information
      >
      <!--<common-information
        title="Duración real"
        tooltip="La duración real de la carrera es el número promedio de semestres que demoran los estudiantes, desde que ingresan al primer año de la carrera hasta que se titulan. El dato de duración real en este caso se ha obtenido a partir de los titulados del año 2021."
      >
        <div v-if="realDuration">
          {{ realDuration.toLocaleString("es-CL") }} semestres.
        </div>
        <div v-else>Sin información.</div>
      </common-information>-->
      <common-information
        title="Costo anual 2023"
        tooltip="Corresponde al pago anual que deberás hacer a la institución por tus estudios. Para mayor información consulta en la institución."
      >
        ${{ tuition ? tuition.toLocaleString("es-CL") : "Sin información." }}
      </common-information>
      <v-expansion-panels
        v-if="showAdditionalInformation"
        v-model="complementaryExpanded"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <common-panel-header
              reference="complementary"
              :panelData="{
                careerName: career.name,
                careerInstitution: institutionName,
                careerCode: career.code,

                source,
              }"
              :selected="complementaryExpanded === 0"
            >
              <v-icon class="color--primary me-2"
                >mdi-file-document-plus-outline</v-icon
              >
              <span style="color: #062053"
                ><b>Información complementaria</b></span
              >
            </common-panel-header>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <common-information
              title="Empleabilidad"
              background-color="#CFDFFE"
              tooltip="Este indicador mide la proporción de titulados de una carrera que obtiene un ingreso superior al ingreso mínimo al cabo del primer año después de su titulación."
            >
              <div class="color--primary-40-t">
                <div v-if="employability">
                  {{ employability.toLocaleString("es-CL") }}%
                  <br />
                  personas empleadas al primer año
                </div>
                <div v-else>Sin información.</div>
              </div>
            </common-information>

            <common-information
              title="Ingreso"
              tooltip="Los ingresos al 4° año de titulación corresponden al promedio de ingresos percibidos por las cohortes de titulados 2015, 2016 y 2017."
              background-color="#CFDFFE"
            >
              <div class="color--primary-40-t">
                {{ salary ? salary : "Sin información." }}
                <br />
              </div>
            </common-information>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// import LastScore from "./LastScore.vue";
// import WeightedScore from "./WeightedScore.vue";
export default {
  name: "Career",
  components: {
    // LastScore,
    //WeightedScore
  },
  data() {
    return {
      complementaryExpanded: false,
    };
  },
  props: {
    status: {
      required: false,
      type: Number,
    },
    source: {
      required: true,
      type: String,
    },
    institutionName: {
      required: false,
      type: String,
    },
    selected: {
      required: true,
      type: Number,
    },
    applicationScore: {
      required: false,
      type: Number,
    },
    applicationScoreBea: {
      required: false,
      type: Number,
    },
    applicationScorePace: {
      required: false,
      type: Number,
    },
    career: {
      required: false,
      type: Object,
    },
    showAdditionalInformation: {
      required: false,
      type: Boolean,
      default: false,
    },
    showScoreInformation: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      openLink: "openLink",
    }),
  },

  computed: {
    ...mapGetters({
      student: "student",
    }),
    benefits() {
      return this.career.program_benefits_label == 1;
    },
    duration() {
      return this.career.report.duration;
    },
    realDuration() {
      return this.career.report.real_duration;
    },
    accreditation() {
      return this.career.campus.institution.accredited_years;
    },
    tuition() {
      return this.career.report.tuition;
    },
    salary() {
      if (this.career.report.salary_min && this.career.report.salary_max) {
        return ` Desde $${this.career.report.salary_min.toLocaleString(
          "es-CL"
        )} a $${this.career.report.salary_max.toLocaleString("es-CL")}`;
      } else if (this.career.report.salary_min) {
        return ` Desde $${this.career.report.salary_min.toLocaleString(
          "es-CL"
        )}`;
      }
      return "Sin información.";
    },
    reg() {
      return !this.bea && !this.pace;
    },
    bea() {
      return this.student.BEA && this.is_full_bea !== null;
    },
    pace() {
      return this.student.PACE && this.is_full_pace !== null;
    },
    last_score() {
      return this.career.last_score;
    },
    is_full_bea() {
      return this.career.report.is_full_bea;
    },
    is_full_pace() {
      return this.career.report.is_full_pace;
    },
    is_full_reg() {
      return this.career.report.is_full_reg;
    },
    last_score_reg() {
      return this.career.report.last_score;
    },
    last_score_bea() {
      return this.career.report.last_score_bea;
    },
    last_score_pace() {
      return this.career.report.last_score_pace;
    },
    employability() {
      return this.career.report.employability;
    },

    regularScore() {
      if (this.applicationScoreBea && this.is_full_bea !== null) {
        return this.applicationScoreBea;
      }
      return this.applicationScore;
    },
    paceScore() {
      if (this.is_full_pace !== null) {
        return this.applicationScorePace;
      }
      return this.applicationScore;
    },
    hasSpecial() {
      return this.career.tests.includes("Prueba Especial");
    },
    hasBoth() {
      if (
        this.career.tests.includes("Historia y Ciencias Sociales") &&
        this.career.tests.includes("Ciencias")
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style>
.background-text {
  margin-top: 8px;
  color: white !important;
  background: var(--bg-color);
  box-shadow: 3px 0 0 var(--bg-color), -3px 0 0 var(--bg-color);
  padding: 5px 3px;
}
</style>
