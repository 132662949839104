var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-module',_vm._b({},'base-module',_vm.$props,false),[_c('common-text',{staticClass:"text-title bold mb-5",attrs:{"text":_vm.title}}),_c('common-text',{staticClass:"text-body",staticStyle:{"line-height":"22px"},attrs:{"text":_vm.description}}),_vm._l((_vm.computedScores),function(score,index){return _c('div',{key:index,staticClass:"rounded pa-4 mb-2 text-left",style:({
      background:
        score.test_label == 6 || score.test_label == 7
          ? '#062053'
          : '#1161F8',
      color: '#FFE1E1',
    })},[_c('div',{staticStyle:{"font-weight":"700 !important"}},[_vm._v(" "+_vm._s(score.test_label_name))]),_c('div',[_vm._v(_vm._s(score.score)+" puntos")])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }