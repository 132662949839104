<template>
  <div
    style="height: 100vh; max-height: 100vh; box-sizing: border-box"
    :class="mobile ? '' : 'ms-10 me-10'"
  >
    <div style="position: relative">
      <div style="position: absolute; height: 100%; width: 100%">
        <slot name="icons"> </slot>
      </div>
    </div>
    <div
      :style="mobile ? 'height:10vh' : 'height:15vh'"
      style="position: relative; z-index: 5"
      class="pa-6 w-100"
    >
      <v-icon
        color="#3662E3"
        class="mt-4"
        style="position: absolute; z-index: 5"
        v-if="screens && screens.length > 0"
        @click="$emit('goBack')"
        >mdi-arrow-left</v-icon
      >
      <div class="w-100" :class="leftLogo ? 'text-left' : 'text-center'">
        <img
          :class="
            leftLogo
              ? mobile
                ? 'me-auto'
                : 'ms-8 mt-8 me-auto'
              : 'ms-auto me-auto'
          "
          :src="
            whiteLogo
              ? require('@/assets/AdmisionEscolar.png')
              : require('@/assets/AdmisionEscolarBlue.png')
          "
          style="z-index: 5; height: 60px"
        />
      </div>
    </div>
    <div
      style="height: 80vh"
      :style="mobile ? 'height: 80vh; gap: 10px' : 'height: 75vh; gap: 20px'"
      class="d-flex pa-6"
      :class="mobile ? 'flex-column' : 'flex-row'"
    >
      <slot name="content"></slot>
    </div>
    <div style="height: 10vh" class="pa-6"></div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Screen",
  props: {
    whiteLogo: {
      required: false,
      default: false,
      type: Boolean,
    },
    leftLogo: {
      required: false,
      default: false,
      type: Boolean,
    },
    screens: {
      required: true,
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      mobile: "mobile",
    }),
  },
};
</script>
