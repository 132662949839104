<template>
  <div>
    <v-card
      class="mt-3 mb-3 pt-4 rounded-lg"
      style="background: #f8fafc"
      rounded
      :style="cardStyle"
    >
      <div class="ps-4 pe-4 pb-4" v-for="(d, index) in data" :key="index">
        <div class="d-flex" :class="iconUp ? 'flex-column' : 'flex-row'">
          <v-icon
            v-if="d.icon"
            :class="[
              iconUp ? 'me-auto' : 'me-2 mb-auto',
              d.iconColor ? d.iconColor : 'color--sae-primary',
            ]"
            >{{ d.icon.includes("$") ? d.icon : `mdi-${d.icon}` }}</v-icon
          >
          <div v-html="d.text"></div>
        </div>

        <v-divider class="mt-4" v-if="index < data.length - 1"></v-divider>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "SaeCard",

  props: {
    data: {
      required: true,
      type: Array,
    },
    iconUp: {
      required: false,
      default: false,
      type: Boolean,
    },
    cardStyle: {
      required: false,
      type: String,
      default: "",
    },
    background: {
      required: false,
      default: "bg--sae-primary-80-t",
      type: String,
    },
  },
};
</script>
