import { render, staticRenderFns } from "./Shuriken.vue?vue&type=template&id=f9a49c0e&"
import script from "./Shuriken.vue?vue&type=script&lang=js&"
export * from "./Shuriken.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports