<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      style="mix-blend-mode: multiply"
      opacity="0.5"
      filter="url(#filter0_f_296_12690)"
    >
      <circle cx="16" cy="16" r="6" fill="#66D6E7" />
    </g>
    <circle cx="16" cy="16" r="2" fill="#66D6E7" />
    <defs>
      <filter
        id="filter0_f_296_12690"
        x="0"
        y="0"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="5"
          result="effect1_foregroundBlur_296_12690"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "SaeDot.svg",
};
</script>
