<template>
  <div>
    <common-text :text="title" style="margin-bottom: 2px" />
    <v-timeline :align-top="alignTop" dense small>
      <v-timeline-item
        v-for="item in items"
        :key="item"
        :small="small"
        :icon="icon"
        :fill-dot="fillDot"
        class="text-left"
      >
        <common-text
          v-if="item.title && !item.reversed"
          class="bold"
          :text="item.title"
        />
        <common-text v-if="item.subtitle" :text="item.subtitle" />
        <common-text
          v-if="item.title && item.reversed"
          class="bold"
          :text="item.title"
        />
      </v-timeline-item>
    </v-timeline>
  </div>
</template>
<script>
export default {
  name: "CommonTimeline",
  props: {
    small: {
      required: false,
      default: false,
      type: Boolean,
    },
    alignTop: {
      required: false,
      default: true,
      type: Boolean,
    },
    icon: {
      required: false,
      default: null,
    },
    items: {
      required: true,
      type: Array,
    },
    fillDot: {
      required: false,
      default: false,
      type: Boolean,
    },
    title: {
      required: false,
      default: "",
      type: String,
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
};
</script>
