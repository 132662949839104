import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import Flower from "@/components/icons/Flower.vue";
import Shuriken from "@/components/icons/Shuriken.vue";
import FatFlower from "@/components/icons/FatFlower.vue";
import Sep from "@/components/icons/Sep.vue";
import SaeDot from "@/components/icons/SaeDot.vue";
import Circles from "@/components/icons/Circles.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      flower: {
        // name of our custom icon
        component: Flower, // our custom component
      },
      shuriken: {
        component: Shuriken,
      },
      fatflower: {
        component: FatFlower,
      },
      saedot: {
        component: SaeDot, // our custom component
      },
      circles: {
        component: Circles,
      },
      sep: {
        component: Sep,
      },
    },
  },
});
