<template>
  <base-module v-bind="$props">
    <div class="text-title bold">Estas son carreras que te interesan</div>
    <div class="text-subtitle">
      según tus últimas postulaciones y/o tus respuestas a la encuesta enviada
      por el Mineduc
    </div>
    <br />
    <div class="mb-4">Haz click sobre una carrera para ver el detalle</div>
    <v-expansion-panels elevation="0" v-model="panels">
      <career
        source="preference"
        v-for="(career, index) in careers"
        :selected="index == panels"
        :status="career.status_label"
        :key="index"
        :institution-name="getInstitutionName(career.program)"
        :career="career.program"
        show-score-information
        show-additional-information
      />
    </v-expansion-panels>
  </base-module>
</template>
<script>
import Career from "@/components/Career";
import { mapActions } from "vuex";

export default {
  name: "Careers",
  components: { Career },
  mounted() {
    this.track({
      event: "preferences-seen",
      data: { list: this.careers.map((c) => c.program.code).join(",") },
    });
  },
  data() {
    return {
      panels: null,
      tests: [
        {
          name: "Comprensión lectora",
          required: true,
        },
        {
          name: "Matemáticas",
          required: false,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      track: "track",
    }),
    getInstitutionName(career) {
      let institutionName = career.campus.institution.name;
      return `${institutionName} - ${career.campus.name}`;
      /*if (
        this.careers
          .map((c) => {
            return {
              institutionName: c.program.campus.institution.name,
              careerName: c.program.name,
            };
          })
          .filter(
            (obj) =>
              obj.careerName == name && obj.institutionName == institutionName
          ).length > 1
      ) {

      }
      return institutionName;*/
    },
  },
  props: {
    startColor: {},
    endColor: {},
    careers: {
      required: true,
      type: Array,
    },
  },
};
</script>
