<template>
  <v-btn
    rounded
    elevation="0"
    v-bind="$props"
    class="bold text-none"
    @click="$emit('click')"
  >
    <div>
      <slot></slot></div
  ></v-btn>
</template>
<script>
export default {
  name: "CommonButton",

  props: {
    color: {
      required: false,
      default: "#1161F8",
      type: String,
    },
    block: {},
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    large: {},
    loading: {},
    small: {},
    xLarge: {},
    xSmall: {},
  },
};
</script>
