<template>
  <base-module v-bind="$props">
    <common-text class="text-title bold mb-5" :text="title" />
    <common-text
      class="text-body"
      style="line-height: 22px"
      :text="description"
    />
    <div
      v-for="(score, index) in computedScores"
      :key="index"
      class="rounded pa-4 mb-2 text-left"
      :style="{
        background:
          score.test_label == 6 || score.test_label == 7
            ? '#062053'
            : '#1161F8',
        color: '#FFE1E1',
      }"
    >
      <!-- -->
      <div style="font-weight: 700 !important">
        {{ score.test_label_name
        }}<!--<span v-if="isPreviousScore(score)"> *</span>-->
      </div>
      <div>{{ score.score }} puntos</div>
    </div>
    <!--<div v-if="hasPreviousScore" class="color--primary">
      <div class="bold" style="height: 15px">*</div>
      <div>Puntaje obtenido de aplicación anterior</div>
    </div>-->
    <!--<div v-if="pace" class="color--primary">
      <div class="bold" style="height: 15px">**</div>

      <div>
        Recuerda que eres <b>habilitada(o) PACE</b>, por lo que podrás postular
        con los puntajes que obtuviste en la PAES por la vía regular y, además,
        podrás postular con tu <b>puntaje ponderado PACE</b>.
      </div>
    </div>-->
  </base-module>
</template>
<script>
export default {
  name: "AllScores",
  props: {
    scores: {
      required: true,
      type: String,
    },
    title: {
      required: false,
      type: String,
      default: "Tus puntajes",
    },
    description: {
      required: false,
      type: String,
      default: "Tus puntajes",
    },
    pace: {
      required: true,
      type: Boolean,
    },
    startColor: {},
    endColor: {},
  },
  methods: {
    isPreviousScore(score) {
      if (
        score.test_label <= 5 &&
        (score.year == 2022 || (score.year == 2023 && score.period_label == 2))
      ) {
        return true;
      }
      return false;
    },
  },
  computed: {
    computedScores() {
      return this.scores.filter((score) => score.show);
    },
    hasPreviousScore() {
      return (
        this.scores.filter((score) => this.isPreviousScore(score)).length > 0
      );
    },
  },
};
</script>
