<template>
  <base-module v-bind="$props">
    <div class="text-title">
      <v-icon large color="#FF0C4F">$flower</v-icon>
      <common-text :text="title" />
    </div>

    <common-timeline
      class="mt-5"
      :items="dates"
      small
      :align-top="false"
      fill-dot
    ></common-timeline>
    <div v-if="button" class="w-100 text-center">
      <common-button @click="openLink({ link: button.link, from: 'date' })">{{
        button.text
      }}</common-button>
    </div>
  </base-module>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Dates",
  props: {
    title: {
      required: true,
      type: String,
    },
    dates: {
      required: true,
      type: Array,
    },
    button: {
      required: false,
      type: Object,
    },
    startColor: {},
    endColor: {},
  },
  methods: {
    ...mapActions({
      openLink: "openLink",
    }),
  },
};
</script>
