<template>
  <base-module v-bind="$props">
    <div class="text-heading">{{ title }}</div>

    <div class="mt-2 text-subtitle" v-html="subtitle" />
    <div class="d-flex flex-column text-center">
      <common-button
        large
        class="ms-auto me-auto mt-4 color--neutral-100-t"
        v-for="(button, index) in buttons"
        :key="index"
        @click="
          openLink({
            link: button.link,
            from: 'resources',
          })
        "
      >
        {{ button.text }}
      </common-button>
    </div>
  </base-module>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Resources",
  props: {
    startColor: {},
    endColor: {},
    title: {
      required: false,
      default: "¿Cómo puedo prepararme para la PAES 2023?",
    },
    subtitle: {
      required: false,
      default:
        "¡Con preparación adicional para la PAES, puedes aumentar tus puntajes!<br/><br/>Prepárate para la prueba con el material que puedes encontrar en:",
    },
    buttons: {
      required: false,
      default: [
        {
          link: "https://acceso.mineduc.cl/material-de-preparacion-paes/",
          text: "Acceso mineduc",
        },
        {
          link: "https://portaldemre.demre.cl/publicaciones/listado-2024",
          text: "Demre",
        },
      ],
    },
  },
  methods: {
    ...mapActions({
      openLink: "openLink",
    }),
  },
};
</script>
