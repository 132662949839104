<template>
  <div
    style="
      width: 100vw;
      height: 100vh;
      background: linear-gradient(180.38deg, #cfdffe 57.2%, #ffffff 99.67%);
    "
    class="d-flex align-items-center justify-content-center"
  >
    <div class="ma-auto d-flex" style="max-width: 50%">
      <div class="text-title">
        Los resultados del proceso de selección estarán disponibles el 17 de
        enero a mediodía en
        <a href="https://acceso.mineduc.cl/portal-matricula/"
          >acceso.mineduc.cl</a
        >. <br /><br />Si fuiste seleccionada/o en un programa, recuerda
        matricularte del 18 al 20 de enero.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Blocker",
};
</script>
