<template>
  <div
    style="width: 100vw; height: 100vh"
    class="d-flex align-items-center justify-content-center"
  >
    <div class="ma-auto d-flex" v-if="!error">
      <div class="text-title" v-if="!disabled">Cargando cartilla...</div>

      <v-progress-circular
        v-if="!disabled"
        class="ms-4"
        color="#1161F8"
        indeterminate
      ></v-progress-circular>
      <div class="text-title" v-else>
        Recurso no encontrado.
        <br />
        <common-button
          class="mt-8"
          @click="
            openLink({
              link: 'https://acceso.mineduc.cl/',
              from: 'not-found',
            })
          "
          large
          >Portal de acceso</common-button
        >
      </div>
    </div>
    <div v-else class="ma-auto d-flex">
      <div class="text-title">
        El volumen de solicitudes actuales es muy grande. Por favor, intenta
        ingresar nuevamente en unos minutos.
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Loader",
  props: {
    disabled: {
      required: false,
      default: false,
    },
    error: {
      required: false,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      openLink: "openLink",
    }),
  },
};
</script>
