<template>
  <base-module v-bind="$props">
    <v-icon color="#1161F8" x-large>$shuriken</v-icon>
    <div class="text-title bold mt-4">
      ¡Busca más carreras que te interesen!
    </div>
    <div class="mb-4 mt-4">
      Para saber más información sobre otras carreras, selecciona los filtros
      para comenzar tu búsqueda.
    </div>
    <v-select
      solo
      :items="filteredRegions"
      :loading="filteredRegions.length == 0"
      :disabled="filteredRegions.length == 0"
      v-model="region"
      no-data-text="No hay datos disponibles"
      item-text="name"
      item-value="id"
      flat
      label="Región*"
    >
    </v-select>
    <v-select
      solo
      v-model="area"
      :items="filteredAreas"
      :loading="filteredAreas.length == 0"
      :disabled="filteredAreas.length == 0"
      no-data-text="No hay datos disponibles"
      item-text="name"
      item-value="id"
      flat
      label="Área de conocimiento*"
    >
    </v-select>
    <v-select
      solo
      v-model="institution"
      :items="filteredInstitutions"
      :loading="filteredInstitutions.length == 1"
      :disabled="filteredInstitutions.length == 1"
      no-data-text="No hay datos disponibles"
      item-text="name"
      item-value="code"
      flat
      label="Universidad"
    >
    </v-select>
    <v-select
      solo
      v-model="career"
      :loading="filteredCareers.length == 1"
      :disabled="filteredCareers.length == 1"
      :items="filteredCareers"
      item-text="name"
      item-value="code"
      no-data-text="No hay datos disponibles"
      flat
      label="Carrera"
    >
    </v-select>
    <!--<v-select
      solo
      v-model="scoreRange"
      :loading="false"
      :disabled="false"
      :items="[
        { name: 'Todos los puntajes' },
        { name: '< 500' },
        { name: '500 - 600' },
        { name: '601 - 700' },
        { name: '701 - 800' },
        { name: '> 800' },
      ]"
      item-text="name"
      item-value="name"
      no-data-text="No hay datos disponibles"
      flat
      label="Puntaje última persona seleccionada"
    >
    </v-select>-->
    <div class="w-100 text-left">*Campos obligatorios</div>
    <div class="w-100 text-center">
      <common-button
        :disabled="region == null || area == null"
        :style="{ cursor: searching ? '' : '' }"
        @click="search(true)"
        class="ms-auto me-auto color--neutral-100-t"
        :loading="searching"
        >Buscar</common-button
      >
    </div>
    <!--paginate careers -->

    <v-expansion-panels elevation="0" v-model="panels">
      <career
        source="searcher"
        :show-score-information="false"
        show-additional-information
        v-for="(career, index) in searchedPrograms"
        :selected="index == panels"
        :key="index"
        :institution-name="getInstitutionName(career)"
        :career="career"
        class="mt-4 rounded"
        style="background: #f4f8ff"
      ></career>
    </v-expansion-panels>

    <v-pagination
      v-model="page"
      class="my-4"
      @input="search(false)"
      :disabled="searching"
      :length="totalPages"
      v-if="hasManyPages"
    >
    </v-pagination>
    <div
      class="text-center mt-5"
      v-if="searchedFirst && searchedPrograms.length == 0"
    >
      No se encontraron resultados.
    </div>

    <!-- end paginate careers -->
    <!-- careers -->

    <!-- end careers -->
    <!-- <common-box
      icon="$flower"
      icon-color="#3579F9"
      color="#88B0FC"
      inline
      class="mt-4"
    >
      <div class="color--primary">
        Recuerda que <b>no es necesario</b> quitar opciones de tu postulación
        para agregar nuevas carreras, puedes agregar hasta 20 opciones.
      </div>
    </common-box>-->
  </base-module>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Career from "@/components/Career.vue";
export default {
  name: "Searcher",
  components: { Career },

  methods: {
    getInstitutionName(career) {
      let institutionName = career.campus.institution.name;
      return `${institutionName} - ${career.campus.name}`;
      /*if (
        this.careers
          .map((c) => {
            return {
              institutionName: c.program.campus.institution.name,
              careerName: c.program.name,
            };
          })
          .filter(
            (obj) =>
              obj.careerName == name && obj.institutionName == institutionName
          ).length > 1
      ) {

      }
      return institutionName;*/
    },
    /*processScoreRange() {
      if (this.scoreRange && this.scoreRange != "Todos los puntajes") {
        if (this.scoreRange.includes("< ")) {
          return { last_score__lt: this.scoreRange.replace("< ", "") };
        } else if (this.scoreRange.includes("> ")) {
          return { last_score__gt: this.scoreRange.replace("> ", "") };
        } else {
          let scores = this.scoreRange.split(" - ");
          return {
            last_score__gt: scores[0],
            last_score__lt: scores[1],
          };
        }
      }
      return {};
    },*/
    search(noPage) {
      let mixpanelData = {};
      if (!this.searching) {
        this.searching = true;
        let params = {};
        if (this.region && this.region > -1) {
          mixpanelData["region"] = this.region;
          params["campus__region_label"] = this.region;
        }
        if (this.area) {
          mixpanelData["area"] = this.area;
          params["generic_program__area_label"] = this.area;
          // if no page, we add scores by default.
          // CASE 1: first search
          if (this.scores) {
            let studentScore = this.scores.find(
              (s) => s.area_label == this.area
            );
            if (studentScore !== undefined) {
              studentScore = studentScore.score;
              params["last_score__gte"] = studentScore - 30;
              params["last_score__lte"] = studentScore + 30;
            }
          }
        }
        if (this.institution) {
          mixpanelData["institution"] = this.institution;
          params["campus__institution__code"] = this.institution;
        }
        if (this.career) {
          mixpanelData["career"] = this.career;

          params["generic_program__code"] = this.career;
        }
        /*if (this.scoreRange) {
          mixpanelData["score"] = this.scoreRange;
          params = { ...params, ...this.processScoreRange() };
        }*/
        if (noPage) {
          this.track({ event: "searcher-search", data: mixpanelData });
          this.page = 1;
        } else {
          mixpanelData["page"] = this.page;
          this.track({ event: "searcher-search", data: mixpanelData });
        }
        params["page"] = this.page;
        // send search will actually search everything.
        this.sendFirstSearch(params);
      }
    },

    sendFirstSearch(params) {
      /*
      SECOND PART: We get the total amount of results.
       */
      this.getPrograms({ params, set: false })
        .then((data) => {
          this.hasManyPages = data.count > 10;
          this.totalResults = data.count;
          this.totalPages = Math.ceil(data.count / 10);
          let results = data.results;
          this.searchedPrograms = results;
          this.track({
            event: "search-results",
            data: {
              list: this.searchedPrograms.map((c) => c.program.code).join(","),
            },
          });

          this.searching = false;
          this.searchedFirst = true;
        })
        .catch(() => {
          this.searching = false;
          this.searchedFirst = true;
        });
    },
    ...mapActions({
      getRegions: "getRegions",
      getAreas: "getAreas",
      getInstitutions: "getInstitutions",
      getPrograms: "getPrograms",
      track: "track",
    }),

    resetArea() {
      // done
      this.area = null;

      this.resetInstitution();
    },
    resetInstitution() {
      this.institution = null;

      this.resetCareer();
    },
    resetCareer() {
      this.career = null;
    },
  },
  watch: {
    region(newVal) {
      this.resetArea();
      this.track({
        event: `searcher-select-region`,
        data: { region: newVal },
      });
    },
    area(newVal) {
      this.resetInstitution();
      this.track({
        event: `searcher-select-area`,
        data: { area: newVal },
      });
    },
    institution(newVal) {
      this.resetCareer();
      this.track({
        event: `searcher-select-institution`,
        data: { institution: newVal },
      });
    },
    career(newVal) {
      this.track({
        event: `searcher-select-career`,
        data: { career: newVal },
      });
    },
  },
  computed: {
    ...mapGetters({
      careers: "careers",
      institutions: "institutions",
      areas: "areas",
      regions: "regions",
      programs: "programs",
    }),
    filteredRegions() {
      return [
        { name: "Todas", id: -1 },
        ...this.programs
          .map((p) => {
            return {
              name: p.region_name,
              id: p.region_id,
            };
          })
          .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1))
          .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i) // unique;
          .filter((p) => {
            return p.name != null && p.id != null;
          }),
      ];
    },
    filteredAreas() {
      let programs = this.programs;

      if (this.region && this.region > -1) {
        programs = programs.filter((p) => p.region_id == this.region);
      }
      let results = programs
        .map((p) => {
          return { name: p.area_name, id: p.area_id };
        })
        .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i) // unique
        .filter((p) => {
          return p.name != null && p.id != null;
        });
      results.sort((a, b) => a.name.localeCompare(b.name));
      return results;
    },
    filteredInstitutions() {
      let programs = this.programs;
      if (this.region && this.region > -1) {
        programs = programs.filter((p) => p.region_id == this.region);
      }
      if (this.area) {
        programs = programs.filter((p) => p.area_id == this.area);
      }
      let results = programs
        .map((p) => {
          return { name: p.institution_name, code: p.institution_code };
        })
        .filter((v, i, a) => a.findIndex((v2) => v2.code === v.code) === i) // unique;
        .filter((p) => {
          return p.name != null && p.code != null;
        });
      results.sort((a, b) => a.name.localeCompare(b.name));
      return [{ name: "Todas las instituciones", code: null }, ...results];
    },
    filteredCareers() {
      let programs = this.programs;
      if (this.region && this.region > -1) {
        programs = programs.filter((p) => p.region_id == this.region);
      }
      if (this.area) {
        programs = programs.filter((p) => p.area_id == this.area);
      }
      if (this.institution) {
        programs = programs.filter(
          (p) => p.institution_code == this.institution
        );
      }
      let results = programs

        .map((p) => {
          return { name: p.generic_program_name, code: p.generic_program_code };
        })

        .filter((v, i, a) => a.findIndex((v2) => v2.code === v.code) === i) // unique;
        .filter((p) => {
          return p.name != null && p.code != null;
        });
      results.sort((a, b) => a.name.localeCompare(b.name));
      return [{ name: "Todas las carreras", code: null }, ...results];
    },
  },
  data() {
    return {
      page: 1,
      totalPages: 0,
      // new
      totalFilteredResults: 0,
      offset: 0,
      // end new
      totalResults: 0,
      hasManyPages: false,
      searching: false,
      searchedFirst: false,
      panels: null,
      region: null,
      area: null,
      institution: null,
      career: null,
      scoreRange: null,
      searchedPrograms: [],
    };
  },

  props: {
    startColor: {},
    endColor: {},
    scores: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style>
.score-background {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    36deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(220, 246, 244, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
</style>
