import Vue from "vue";
import Vuex from "vuex";
import mixpanel from "mixpanel-browser";
import services from "../services";
import { programs } from "./programs.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    identifier: "", // student identifier
    primer: "", // primer type (score, application, unregistered)
    student: null, // student information
    error: false,
    regions: [],
    areas: [],
    institutions: [],
    careers: [],
    programs: programs,
    // sae
    screenWidth: 0,
    uuid: null,
    showMixpanel: null,
    personalized: false,
  },
  getters: {
    student: ({ student }) => student,
    identifier: ({ identifier }) => identifier,
    primer: ({ primer }) => primer,
    error: ({ error }) => error,
    regions: ({ regions }) => regions,
    institutions: ({ institutions }) => institutions,
    careers: ({ careers }) => careers,
    areas: ({ areas }) => areas,
    programs: ({ programs }) => programs,
    // sae
    mobile: ({ screenWidth }) => screenWidth <= 768,
    screenWidth: ({ screenWidth }) => screenWidth,
    uuid: ({ uuid }) => uuid,
    showMixpanel: ({ showMixpanel }) => showMixpanel,
    personalized: ({ personalized }) => personalized,
  },
  mutations: {
    setIdentifier(state, { identifier }) {
      /* sets identifier of student */
      state.identifier = identifier;
    },
    setStudent(state, { student }) {
      /* sets student information */
      state.student = student;
    },
    setPrimer(state, { primer }) {
      /* sets primer type (score, application or unregistered) */
      state.primer = primer;
    },
    setError(state, { error }) {
      /* sets primer type (score, application or unregistered) */
      state.error = error;
    },
    setRegions(state, { regions }) {
      state.regions = regions;
    },
    setAreas(state, { areas }) {
      state.areas = areas;
    },
    setInstitutions(state, { institutions }) {
      state.institutions = institutions;
    },
    setCareers(state, { careers }) {
      state.careers = careers;
    },
    // sae
    updateShowMixpanel(state, { showMixpanel }) {
      state.showMixpanel = showMixpanel;
    },
    updateScreenWidth(state, { screenWidth }) {
      state.screenWidth = screenWidth;
    },
    updateUUID(state, { uuid }) {
      state.uuid = uuid;
    },
    setPersonalized(state, { personalized }) {
      state.personalized = personalized;
    },
  },
  actions: {
    getServerStatus() {
      return services.getServerStatus().then((response) => {
        return response;
      });
    },
    track({ getters, commit }, { event, data }) {
      /* tracks mixpanel event <event> with <data> properties.
         in each tracked event, registers identifier, primer and environment.
      */
      if (getters.personalized != null) {
        data["personalized"] = getters.personalized;
      }
      if (getters.identifier != "") {
        data["mrun"] = getters.identifier;
      }
      if (getters.primer != "") {
        data["primer"] = getters.primer;
      }
      if (getters.uuid != null) {
        data["uuid"] = getters.uuid;
      }
      data["env"] = process.env.VUE_APP_ENVIRONMENT;
      commit("updateShowMixpanel", { showMixpanel: { event, data } });
      setTimeout(() => {
        commit("updateShowMixpanel", { showMixpanel: null });
      }, 5000);
      mixpanel.track(event, data, () => {});
    },
    updateScreenWidth({ commit }, { screenWidth }) {
      commit("updateScreenWidth", { screenWidth });
    },
    updateUUID({ commit }, { uuid }) {
      commit("updateUUID", { uuid });
    },
    setPrimer({ commit, dispatch }, { primer }) {
      /* sets primer type */
      // tracks primer being opened
      dispatch("track", {
        event: `open-${primer}`,
        data: {},
      });
      commit("setPrimer", { primer });
    },
    setError({ commit }, { error }) {
      commit("setError", { error });
    },
    getRegions({ commit }, { params, set = false }) {
      return services.getRegions({ params }).then((response) => {
        if (set) {
          commit("setRegions", { regions: response.data });
        }
        return response.data;
      });
    },
    getAreas({ commit }, { params, set = false }) {
      return services.getAreas({ params }).then((response) => {
        if (set) {
          commit("setAreas", { areas: response.data });
        }
        return response.data;
      });
    },
    setPersonalized({ commit }, { personalized }) {
      commit("setPersonalized", { personalized });
    },
    getInstitutions({ commit }, { params, set = false }) {
      return services.getInstitutions({ params }).then((response) => {
        if (set) {
          commit("setInstitutions", { institutions: response.data });
        }
        return response.data;
      });
    },
    // eslint-disable-next-line
    getPrograms({ commit }, { params, set = false }) {
      return services.getPrograms({ params }).then((response) => {
        if (set) {
          commit("setCareers", { careers: response.data });
        }
        if ("data" in response) {
          return response.data;
        } else if ("results" in response) {
          return response.results;
        }
      });
    },
    getStudent({ commit, dispatch }, { identifier, number }) {
      /* gets student information. retry 3 times max */
      commit("setIdentifier", { identifier: identifier });
      return services
        .getStudent({ params: {}, identifier })
        .then((response) => {
          let student = response.data;
          commit("setError", { error: false });
          commit("setStudent", { student });
          return student;
        })
        .catch((error) => {
          if (error.response && error.response.status == 500) {
            let student = { treatments: [], mrun: identifier };
            commit("setStudent", { student });
            return student;
          } else {
            if (number > 0) {
              // try again
              setTimeout(() => {
                dispatch("getStudent", { identifier, number: number - 1 });
              }, Math.floor(Math.random() * 3000));
            } else {
              commit("setError", { error: true });
            }
          }
        });
    },
    openLink({ dispatch }, { link, from }) {
      /* triggers an open link event. */
      dispatch("track", {
        event: `open-link-${from}`,
        data: {
          link,
        },
      });
      window.open(link, "_blank");
    },
    request(
      { commit },
      { endpoint, type, body, successMessage, errorMessage, params }
    ) {
      return services
        .request({ endpoint, type, body, params })
        .then((response) => {
          if (successMessage) {
            commit("setToastNotification", {
              toastState: true,
              toastMessage: successMessage,
            });
          }
          return response;
        })
        .catch((error) => {
          if (errorMessage) {
            commit("setToastNotification", {
              toastState: false,
              toastMessage: errorMessage,
            });
          }
          throw error;
        });
    },
  },
  modules: {},
});
