<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C10.1167 24 8.56781 22.5683 8.38226 20.734C6.95398 21.8999 4.8464 21.817 3.51472 20.4853C2.18304 19.1536 2.10013 17.046 3.26601 15.6177C1.43166 15.4322 -8.23209e-08 13.8833 0 12C1.49055e-07 10.1167 1.43166 8.56781 3.26602 8.38226C2.10014 6.95398 2.18304 4.8464 3.51472 3.51472C4.8464 2.18304 6.95398 2.10013 8.38226 3.26601C8.56782 1.43166 10.1167 1.49055e-07 12 0C13.8833 -8.23209e-08 15.4322 1.43166 15.6177 3.26601C17.046 2.10013 19.1536 2.18303 20.4853 3.51472C21.817 4.8464 21.8999 6.95398 20.734 8.38226C22.5683 8.56781 24 10.1167 24 12C24 13.8833 22.5683 15.4322 20.734 15.6177C21.8999 17.046 21.817 19.1536 20.4853 20.4853C19.1536 21.817 17.046 21.8999 15.6177 20.734C15.4322 22.5683 13.8833 24 12 24Z"
    />
  </svg>
</template>
<script>
export default {
  name: "FatFlower.svg",
};
</script>
