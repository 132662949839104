<template>
  <div>
    <div
      class="text-subtitle bold color--primary mt-4"
      :class="dark ? 'color--primary-60-s' : 'color--primary'"
    >
      {{ roundTo(score, 1) }} ptos.
    </div>
    <div
      class="text-big-body"
      :class="dark ? 'color--primary-60-s' : 'color--primary'"
    >
      {{ text }}
    </div>
    <br />
  </div>
</template>
<script>
export default {
  name: "CommonScore",
  props: {
    score: {},
    text: {},
    dark: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  methods: {
    roundTo(input, digits) {
      var rounder = Math.pow(10, digits);
      let newValue = (Math.round(input * rounder) / rounder).toFixed(digits);
      return newValue.toString().replace(".", ",").replace(/,0+$/, "");
    },
  },
};
</script>
