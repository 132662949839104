<template>
  <div
    v-if="loaded"
    :style="{ background: background }"
    class="text-left"
    style="
      overflow-y: auto;
      max-height: 100vh;
      overflow-x: hidden;
      max-width: 100vw;
    "
  >
    <v-card
      class="text-label"
      v-if="!isProd && showMixpanel"
      style="position: absolute; top: 10px; right: 10px; z-index: 1000"
    >
      Trackeando: {{ showMixpanel.event }} <br />Datos:
      <div>
        <div v-for="(value, key) in showMixpanel.data" :key="key">
          <div class="ms-2" v-if="key != 'token'">• {{ key }}: {{ value }}</div>
        </div>
      </div>
    </v-card>
    <!--<v-select
      @change="goTo"
      style="
        position: absolute;
        opacity: 0.5;
        top: 0;
        right: 0;
        z-index: 8;
        background: white;
        width: 200px;
      "
      hide-details
      :items="[
        'intro',
        'input-name',
        'web-platform',
        'applications-coming',
        'how-to-apply',
        'student-can-apply',
        'other-students',
        'vacancies',
        'student-is-priority',
        'contact-info',
        'ending',
      ]"
      v-model="screen"
    />-->
    <!-- a.1 -->
    <div v-if="screen == 'intro'">
      <screen
        key="intro"
        :screens="screens"
        @goBack="goBack"
        whiteLogo
        :leftLogo="mobile ? false : true"
      >
        <!-- icons -->
        <template v-slot:icons>
          <img
            src="@/assets/Bag.png"
            class="mb-7"
            style="
              height: 64px;
              width: 77px;
              margin-top: 60px;
              transform: translate(-28px, 0);
            "
            v-if="mobile"
          />
          <img
            v-else
            src="@/assets/BagTop.png"
            style="position: absolute; top: -10px; height: 144px; right: 10vw"
          />
          <img
            src="@/assets/Pencil.png"
            style="position: absolute; height: 87px; top: 85vh; left: 0px"
            v-if="mobile"
          />
          <img
            src="@/assets/PencilBottom.png"
            style="
              position: absolute;
              height: 146px;
              top: calc(100vh - 146px);
              left: 15vw;
            "
            v-else
          />
          <img
            src="@/assets/BookLeft.png"
            style="position: absolute; top: 25vh; height: 58px; right: -12px"
            v-if="mobile"
          />
          <img
            v-else
            src="@/assets/BookLeft.png"
            style="position: absolute; top: 25vh; height: 158px; right: -43px"
          />
        </template>
        <!-- content -->
        <template v-slot:content>
          <div class="flex-fill">
            <h1
              class="text-heading"
              :class="mobile ? '' : 'ms-8'"
              :style="mobile ? 'margin-top: 60px !important' : ''"
            >
              <span class="highlight">¡Hola!</span>
            </h1>

            <div
              :class="mobile ? 'text-subtitle' : 'text-title ms-8'"
              class="color--neutral-100-t"
              style="position: relative; z-index: 10"
            >
              Te recordamos que el establecimiento educacional {{ schoolName
              }}<b> {{ welcomeText }} {{ nextSchoolGrade }}</b
              >, por lo tanto, para encontrar un nuevo establecimiento público o
              particular subvencionado,
              <b
                >deberás postular a través del Sistema de Admisión Escolar
                (SAE), a partir del 4 de agosto.</b
              ><br /><br />¿Quieres conocer más sobre el SAE?<br />Haz click en
              siguiente.
            </div>
          </div>
          <div style="width: 100%" class="text-center mt-auto">
            <sae-button
              :buttonStyle="{ width: '100%', 'max-width': '300px' }"
              :primary="false"
              class="ms-auto me-auto"
              @click="personalized ? goTo('web-platform') : goTo('input-name')"
              >Siguiente<v-icon class="ms-2 color--sae-primary"
                >mdi-arrow-right</v-icon
              ></sae-button
            >
          </div>
        </template>
        <!-- icons -->
      </screen>
    </div>
    <div v-else-if="screen == 'input-name'">
      <screen
        key="input-name"
        ref="input-name"
        :screens="screens"
        @goBack="goBack"
      >
        <template v-slot:icons>
          <div
            style="max-height: 150px; overflow-y: hidden; position: absolute"
            :style="
              mobile
                ? 'right: -10px; top: 15vh;'
                : 'top:calc(100vh - 150px);left:5vw;'
            "
          >
            <img
              :style="mobile ? 'height: 110px' : 'height:200px;'"
              src="@/assets/Clip.png"
            />
          </div>
        </template>
        <template v-slot:content>
          <div :class="mobile ? '' : 'flex-equal'">
            <h3
              style="color: #3662e3; position: relative"
              class="mb-3 text-title bold"
            >
              <div style="z-index: 20">
                Para poder entregar información personalizada, necesitamos
                algunos datos adicionales.
              </div>
            </h3>
          </div>
          <div :class="mobile ? 'flex-grow-1' : 'flex-equal'" class="relative">
            <sae-input label="Nombre escuela" disabled :value="schoolName">
            </sae-input>
            <sae-input label="Curso" disabled :value="currentSchoolGrade">
            </sae-input>
            <div
              v-if="emphasis"
              class="pa-2 rounded text-body"
              style="background: #e0e9fb; color: #3662e3; margin-bottom: 10px"
            >
              Intenta ingresando los
              <b>nombres y dos apellidos del estudiante</b>
            </div>
            <sae-input
              label="Ingrese nombre completo del estudiante"
              v-model="studentName"
              :autofocus="mobile ? false : true"
            >
            </sae-input>
            <div v-if="foundStudent && students.length > 0">
              <v-card
                rounded
                no-elevation
                class="elevation-0 pa-3 rounded-lg bg--sae-primary-90-t"
              >
                Tenemos en nuestros registros a:<br />
                <b>{{ students[0].name }}</b>
                <br />
                ¿Corresponde a tu estudiante?
                <br />
                <div class="d-flex w-100 mt-5">
                  <v-btn
                    outlined
                    style="background: white !important"
                    class="flex-fill me-1 color--sae-primary"
                    @click="confirmStudent"
                    >Sí</v-btn
                  >
                  <v-btn
                    outlined
                    style="background: white !important"
                    class="flex-fill ms-1 color--sae-primary"
                    @click="rejectStudent"
                    >No</v-btn
                  >
                </div>
              </v-card>
              <br />
            </div>
            <div
              class="d-flex w-100"
              style="gap: 10px"
              :class="mobile ? 'flex-column' : 'flex-row position-bottom'"
            >
              <sae-button
                class="flex-equal"
                :buttonStyle="{ width: '100%' }"
                v-if="!foundStudent"
                @click="findStudent"
                :disabled="studentName.length < 5"
                >Buscar estudiante</sae-button
              >
              <sae-button
                :primary="false"
                class="flex-equal"
                :buttonStyle="{ width: '100%' }"
                @click="
                  students = [];
                  personalized = false;
                  studentName = '';
                  goTo('web-platform');
                  continueWithoutInfo('web-platform');
                "
                >Continuar sin entregar información</sae-button
              >
            </div>
          </div>
        </template>
      </screen>
    </div>
    <div v-else-if="screen == 'other-students'">
      <screen
        key="other-students"
        ref="other-students"
        @goBack="goBack"
        :screens="screens"
      >
        <template v-slot:content>
          <div :class="mobile ? '' : 'flex-equal'">
            <h3 style="color: transparent">.</h3>

            <div class="bg--sae-secondary-90-t pa-6 rounded text-body">
              <div class="d-flex color--success">
                Estudiante verificado
                <div class="ms-auto">
                  <v-icon color="green">mdi-check</v-icon>
                </div>
              </div>
              <v-divider class="mt-2 mb-2"></v-divider>
              <div class="bold mb-2">{{ students[0].name }}</div>

              <div>{{ students[0].grade.institution.name }}</div>

              <div>{{ students[0].grade.grade_label.name }}</div>
            </div>
          </div>
          <div :class="mobile ? '' : 'flex-equal'">
            <h3 class="text-subtitle color--sae-primary mb-2">
              Hemos encontrado otros estudiantes asociados
            </h3>
            <div v-for="(student, index) in students" :key="index">
              <div
                class="bg--sae-secondary-90-t pa-6 rounded mb-3 d-flex flex-column text-body"
                v-if="index > 0"
              >
                <div class="bold mb-2">{{ student.name }}</div>

                <div>{{ student.grade.institution.name }}</div>

                <div>{{ student.grade.grade_label.name }}</div>
              </div>
            </div>

            <div class="w-100 flex-end mt-auto">
              <div
                class="d-flex w-100"
                style="gap: 10px"
                :class="mobile ? 'flex-column' : 'flex-row'"
              >
                <sae-button
                  class="flex-equal"
                  :buttonStyle="{ width: '100%' }"
                  @click="goTo('web-platform')"
                  >Siguiente<v-icon class="ms-2 color--neutral-100-t"
                    >mdi-arrow-right</v-icon
                  ></sae-button
                >
                <sae-button
                  :primary="false"
                  class="flex-equal"
                  :buttonStyle="{ width: '100%' }"
                  @click="
                    students = [];
                    personalized = false;
                    studentName = '';
                    goTo('web-platform');
                    continueWithoutInfo('web-platform');
                  "
                  >Continuar sin entregar información</sae-button
                >
              </div>
            </div>
          </div>
        </template>
      </screen>
    </div>
    <!-- a.2 -->
    <div v-else-if="screen == 'web-platform'">
      <screen
        key="web-platform"
        ref="web-platform"
        :screens="screens"
        @goBack="goBack"
      >
        <template v-slot:icons>
          <img
            v-if="mobile"
            src="@/assets/Pencil.png"
            class="mb-7"
            style="
              transform: scaleX(-1);
              position: absolute;
              height: 100px;
              width: 140px;
              right: 0px;
              top: 70px;
            "
          />
          <img
            v-else
            src="@/assets/PencilFull.png"
            class="mb-7"
            style="
              position: absolute;
              height: 100px;
              width: 140px;
              top: 15vh;
              right: 50vw;
            "
          />
          <img
            v-if="mobile"
            src="@/assets/BookRight.png"
            style="position: absolute; height: 87px; top: 20vh; left: 0px"
          />
          <img
            v-else
            src="@/assets/BookFull.png"
            style="
              position: absolute;
              height: 87px;
              transform: scaleX(-1);
              top: 50vh;
              left: 30px;
            "
          />
        </template>
        <template v-slot:content>
          <div :class="mobile ? '' : 'flex-equal'">
            <div
              class="w-100 mt-3 mb-3"
              :class="mobile ? '' : 'mt-10'"
              style="opacity: 0.9"
            >
              <div
                :class="mobile ? '' : 'ms-10 me-10'"
                class="w-100 d-flex"
                style="
                  background: white;
                  height: 10px;
                  border-radius: 5px 5px 0px 0px;
                "
              >
                <v-icon style="transform: scale(0.4)" x-small color="red"
                  >mdi-circle</v-icon
                >
                <v-icon style="transform: scale(0.4)" x-small color="yellow"
                  >mdi-circle</v-icon
                >
                <v-icon style="transform: scale(0.4)" x-small color="green"
                  >mdi-circle</v-icon
                >
              </div>
              <div
                style="color: white"
                :class="mobile ? '' : 'ms-10 me-10'"
                class="text-center ps-7 pe-7 pt-10 pb-10 text-body web-platform"
              >
                El sistema de admisión escolar es una<br />
                plataforma web única y centralizada,<br />
                en la cual <span class="circle">podrás postular</span> a
                todos<br />
                los establecimientos públicos y<br />
                particulares subvencionados del país.
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-column relative"
            :class="mobile ? '' : 'flex-equal'"
          >
            <div class="text-subtitle bold">
              <span style="z-index: 10"
                >El sistema asigna a las y los estudiantes a los
                establecimientos en base a:</span
              >
            </div>
            <sae-card
              class="text-body"
              :data="[
                { text: 'Preferencias de los apoderados', icon: 'heart' },
                { text: 'Vacantes disponibles', icon: 'face-woman' },
                { text: 'Criterios de prioridad', icon: 'priority-low' },
              ]"
            />
            <div class="w-100 flex-end mt-auto">
              <sae-button
                :class="mobile ? 'ms-auto me-auto' : 'ms-auto'"
                @click="goTo('applications-coming')"
                style="width: 50%"
                :buttonStyle="{ width: '100%' }"
                >Siguiente<v-icon class="ms-2 color--neutral-100-t"
                  >mdi-arrow-right</v-icon
                ></sae-button
              >
            </div>
          </div>
        </template>
      </screen>
    </div>
    <div v-else-if="screen == 'applications-coming'">
      <screen
        key="applications-coming"
        ref="applications-coming"
        v-if="screen == 'applications-coming'"
        :screens="screens"
        @goBack="goBack"
      >
        <template v-slot:icons> </template>
        <template v-slot:content>
          <div :class="mobile ? '' : 'flex-equal'">
            <div :class="mobile ? 'text-title' : 'text-heading'">
              <span class="highlight-white"
                >Se acerca el periodo de postulaciones</span
              >
            </div>
            <div class="mt-2 mb-2 text-subtitle">
              ¡Atención con las siguientes fechas!
            </div>
            <v-icon class="me-auto mt-2 color--sae-primary"
              >mdi-calendar-month</v-icon
            >
          </div>
          <div :class="mobile ? '' : 'flex-equal d-flex flex-column'">
            <div class="text-body">
              Estas son las etapas más importantes del periodo principal
            </div>
            <sae-card
              class="text-body"
              background="bg--sae-primary-90-t"
              iconUp
              :data="[
                {
                  text: '4 de agosto al 1 de septiembre, 2023<br/><b>Periodo principal de postulación</b>',
                  icon: '$saedot',
                },
                {
                  text: '19 al 25 de octubre, 2023<br/><b>Publicación resultados periodo principal</b>',
                  icon: '$saedot',
                },
                {
                  text: '6 al 7 de noviembre, 2023<br/><b>Publicación resultados listas de espera</b>',
                  icon: '$saedot',
                },
              ]"
            />
            <div class="w-100 flex-end mt-auto">
              <sae-button
                :class="mobile ? 'ms-auto me-auto' : 'ms-auto'"
                @click="goTo('how-to-apply')"
                style="width: 50%"
                :buttonStyle="{ width: '100%' }"
                >Siguiente<v-icon class="ms-2 color--neutral-100-t"
                  >mdi-arrow-right</v-icon
                ></sae-button
              >
            </div>
          </div>
        </template>
      </screen>
    </div>
    <!-- b.1 -->
    <div v-else-if="screen == 'how-to-apply'">
      <screen
        key="how-to-apply"
        ref="how-to-apply"
        v-if="screen == 'how-to-apply'"
        :screens="screens"
        @goBack="goBack"
      >
        <template v-slot:icons>
          <img
            src="@/assets/Clip.png"
            :style="mobile ? 'top: 75vh' : 'top: 50vh'"
            style="
              position: absolute;
              right: 4px;
              height: 88px;
              width: 43px;
              z-index: 10;
            "
          />
        </template>
        <template v-slot:content>
          <div :class="mobile ? '' : 'flex-equal'">
            <div>
              <div
                v-if="mobile"
                style="
                  position: absolute;
                  width: 100%;
                  height: 50vh;
                  background: #fef8ff;
                  left: 0;
                  right: 0;
                  top: 0;
                  z-index: 0;
                "
              ></div>
              <!-- max-width: calc(100vw);top: 120px;z-index: 1;position: absolute;max-height:38vh;left:50%;transform:translate(-50%, 0); -->
              <img
                style="opacity: 0.8"
                :style="
                  mobile
                    ? 'display:none'
                    : 'position:absolute;top:50%;transform:translate(0, -50%);max-width:48vw;'
                "
                src="@/assets/BigBag.png"
              />
              <div
                :class="
                  mobile ? 'pb-4' : 'text-center h-100 d-flex flex-column'
                "
                style="z-index: 2; position: relative"
                :style="
                  mobile
                    ? {
                        backgroundImage: `url(${require('@/assets/BigBag.png')})`,
                        backgroundSize: 'cover',
                        width: '100vw',
                        marginLeft: '-1.5rem',
                      }
                    : 'height:calc(100vh - 200px)'
                "
              >
                <h3
                  style="color: #3662e3"
                  class="text-center"
                  :class="mobile ? 'mt-2' : 'mt-auto'"
                >
                  <br />
                  <span
                    class="highlight-dark"
                    style="background: rgba(248, 250, 252, 0.98)"
                    >¿Cómo postular una vez que inicie el periodo de
                    postulación?</span
                  >
                </h3>
                <div class="w-100 text-center" :class="mobile ? '' : 'mb-auto'">
                  <sae-button
                    @click="
                      openLink({
                        link: 'https://www.youtube.com/watch?v=32STTLtols8',
                        from: 'video',
                      })
                    "
                    :primary="false"
                    class="mt-2 mb-2"
                    style="position: relative"
                    >Ver video<v-icon>mdi-chevron-right</v-icon>
                  </sae-button>
                </div>
              </div>
            </div>
          </div>
          <div :class="mobile ? '' : 'flex-equal d-flex flex-column'">
            <sae-card
              background="bg--sae-primary-90-t"
              :class="mobile ? '' : 'ms-8 me-8'"
              class="text-body"
              :data="[
                {
                  icon: 'list-box',
                  text: 'Utiliza los filtros que ofrece la plataforma, revisa la información de la vitrina de los establecimientos y <b>agrégalos a tu listado</b>. Ten presente que <b>no existe un número máximo de establecimientos</b>, <b class=\'color--sae-primary\'>¡Puedes postular a todos los que quieras!</b>',
                },
                {
                  icon: 'priority-high',
                  text: '<b>¡Atención!</b> Recuerda que debes ordenarlos según tu preferencia, es decir, <b class=\'color--sae-primary\'>en primer lugar debes dejar el que más deseas.</b>',
                },
              ]"
            />
            <div class="w-100 flex-end mt-auto">
              <sae-button
                :class="mobile ? 'ms-auto me-auto' : 'ms-auto'"
                @click="
                  personalized ? goTo('student-can-apply') : goTo('vacancies')
                "
                style="width: 50%"
                :buttonStyle="{ width: '100%' }"
                >Siguiente<v-icon class="ms-2 color--neutral-100-t"
                  >mdi-arrow-right</v-icon
                ></sae-button
              >
            </div>
          </div>
        </template>
      </screen>
    </div>
    <div v-else-if="screen == 'student-can-apply'">
      <screen
        key="students-can-apply"
        ref="students-can-apply"
        :screens="screens"
        @goBack="goBack"
      >
        <template v-slot:icons>
          <img
            src="@/assets/Brush.png"
            :style="
              mobile
                ? 'top: 80vh;height: 90px;left: 0px;'
                : 'height: 180px; top: 50vh; left: 50vw'
            "
            style="position: absolute; z-index: 3"
          />
        </template>
        <template v-slot:content>
          <div v-if="havePIE.length > 0" :class="mobile ? '' : 'flex-equal'">
            <div class="ms-3 me-3 mt-3 text-subtitle">
              Detectamos que <br />
              <div v-for="(student, index) in havePIE" :key="index">
                <span
                  class="highlight-white"
                  :class="mobile ? '' : 'text-title'"
                  >{{ student.name }}</span
                >
              </div>
              se encuentra{{ havePIE.length > 1 ? "n" : "" }} en su actual
              establecimiento en un Programa de Integración Escolar (PIE).<br />
              <b>Puedes postular a establecimientos con atributos similares.</b>
            </div>
          </div>
          <div v-else :class="mobile ? '' : 'flex-equal'">
            <div class="ms-3 me-3 mt-3 text-subtitle">
              Puedes postular a <br />
              <div v-for="(student, index) in students" :key="index">
                <span
                  class="highlight-white"
                  :class="mobile ? '' : 'text-title'"
                  >{{ student.name }}</span
                >
              </div>
              a establecimientos con los atributos que más te interesen.
            </div>
          </div>
          <div :class="mobile ? '' : 'flex-equal d-flex flex-column'">
            <sae-card
              :card-style="mobile ? '' : 'padding-bottom:100px'"
              style="position: relative; z-index: 5"
              :data="[
                {
                  icon: 'tune',
                  text: 'Recuerda <b>utilizar los filtros de la plataforma</b> para encontrar tu próximo establecimiento.<br /><br />Una de las características más buscadas por los apoderadoses que las <b>instituciones posean Programa de Integración Escolar.</b>',
                },
              ]"
            />
            <div class="w-100 flex-end mt-auto">
              <sae-button
                :class="mobile ? 'ms-auto me-auto' : 'ms-auto'"
                @click="goTo('vacancies')"
                style="width: 50%"
                :buttonStyle="{ width: '100%' }"
                >Siguiente<v-icon class="ms-2 color--neutral-100-t"
                  >mdi-arrow-right</v-icon
                ></sae-button
              >
            </div>
          </div>
        </template>
      </screen>
    </div>
    <div v-else-if="screen == 'vacancies'">
      <screen
        key="vacancies"
        ref="vacancies"
        :screens="screens"
        @goBack="goBack"
      >
        <template v-slot:icons>
          <img
            v-if="mobile"
            src="@/assets/BasketBall.png"
            style="
              position: absolute;
              right: 0;
              top: 70px;
              height: 94px;
              width: 94px;
            "
          />
          <img
            v-if="mobile"
            src="@/assets/PencilUp.png"
            style="position: absolute; left: 0; top: 40vh; height: 126px"
          />
        </template>
        <template v-slot:content>
          <div :class="mobile ? '' : 'flex-equal'">
            <div class="text-title" :style="mobile ? 'max-width:70%' : ''">
              <span
                class="highlight-white"
                :class="mobile ? '' : 'text-heading'"
                >¿Cómo se asignan las vacantes disponibles?</span
              >
            </div>
            <v-icon
              class="color--sae-primary me-auto"
              :class="mobile ? 'mt-2 mb-2' : 'mt-5 mb-5'"
              >mdi-pin-outline</v-icon
            >
            <div :class="mobile ? 'text-body' : 'text-subtitle'">
              Recuerda que, si el establecimiento
              <b
                >no cuenta con vacantes suficientes para todas las postulaciones
                recibidas</b
              >, el Sistema de Admisión Escolar
              <b
                >ordenará a los estudiantes según los siguientes criterios de
                prioridad</b
              >:
            </div>
          </div>
          <div :class="mobile ? '' : 'flex-equal d-flex flex-column'">
            <sae-card
              background="bg--neutral-100-t"
              class="text-body"
              :data="[
                {
                  icon: 'face-woman',
                  text: 'Hermanos/as en el establecimiento',
                },
                { icon: 'human', text: 'Estudiantes prioritarios*' },
                { icon: '$circles', text: 'Hijos/as de funcionarios' },
                { icon: 'school', text: 'Exalumnos/as' },
              ]"
            />

            <div class="ms-2 me-2 mb-5 text-label">
              *Este criterio se aplica siempre y cuando el porcentaje de
              estudiantes prioritarios por nivel en el establecimiento sea menor
              al 15%.
            </div>
            <div class="w-100 flex-end mt-auto">
              <sae-button
                :class="mobile ? 'ms-auto me-auto' : 'ms-auto'"
                @click="
                  personalized && havePriority.length > 0
                    ? goTo('student-is-priority')
                    : !personalized && students.length == 0
                    ? goTo('contact-info-previous')
                    : goTo('contact-info')
                "
                style="width: 50%"
                :buttonStyle="{ width: '100%' }"
                >Siguiente<v-icon class="ms-2 color--neutral-100-t"
                  >mdi-arrow-right</v-icon
                ></sae-button
              >
            </div>
          </div></template
        >
      </screen>
    </div>
    <div v-else-if="screen == 'student-is-priority'">
      <screen
        key="student-is-priority"
        ref="student-is-priority"
        :screens="screens"
        @goBack="goBack"
      >
        <template v-slot:content>
          <div :class="mobile ? '' : 'flex-equal'">
            <div class="text-title mb-3">
              <div v-for="(student, index) in havePriority" :key="index">
                <span class="highlight-white"
                  >{{ index == 0 ? "¡" : "" }}{{ student.name }}</span
                >
              </div>
              {{
                havePriority.length > 1
                  ? "son prioritarios!"
                  : "es prioritario!"
              }}
            </div>
            <div class="text-body">
              De acuerdo con los registros del Mineduc,
              {{
                havePriority.length > 1 ? "los estudiantes" : "el/la estudiante"
              }}
              <b
                >{{
                  havePriority.length > 1
                    ? "están categorizados como prioritarios y podrán ser beneficiados "
                    : "está categorizado como prioritario y podrá ser beneficiado "
                }}con la Subvención Escolar Preferencial (SEP).</b
              >
              <br />
              <br />
            </div>
          </div>
          <div :class="mobile ? '' : 'flex-equal d-flex flex-column'">
            <div class="text-body">Esto significa que {{ studentNames }}:</div>

            <sae-card
              background="bg--neutral-100-t"
              class="text-body"
              :data="[
                {
                  icon: 'blur-linear',
                  text: 'Contará con el criterio de prioridad de <b>Estudiante Prioritario al momento de postular a Admisión Escolar 2024</b>.',
                },
                {
                  icon: '$sep',
                  text: 'Tendrán gratuidad en establecimientos con convenio SEP.',
                },
                {
                  icon: 'tune',
                  text: 'Recuerda utilizar los filtros de la plataforma para encontrar tu próximo establecimiento.',
                },
              ]"
            />
            <div class="w-100 flex-end mt-auto">
              <sae-button
                :class="mobile ? 'ms-auto me-auto' : 'ms-auto'"
                @click="
                  !personalized && students.length == 0
                    ? goTo('contact-info-previous')
                    : goTo('contact-info')
                "
                style="width: 50%"
                :buttonStyle="{ width: '100%' }"
                >Siguiente<v-icon class="ms-2 color--neutral-100-t"
                  >mdi-arrow-right</v-icon
                ></sae-button
              >
            </div>
          </div>
        </template>
      </screen>
    </div>
    <div v-else-if="screen == 'contact-info-previous'">
      <screen
        key="contact-info-previous"
        ref="contact-info-previous"
        :screens="screens"
        @goBack="goBack"
      >
        <template v-slot:icons>
          <img
            src="@/assets/PencilDown.png"
            style="position: absolute; height: 127px; top: 0; right: 15px"
          />
          <img
            src="@/assets/BasketBallRight.png"
            style="position: absolute; height: 97px; left: 0; top: 10vh"
          />
        </template>
        <template v-slot:content>
          <div :class="mobile ? '' : 'flex-equal'">
            <div v-if="personalized || (!personalized && students.length == 0)">
              <h3
                class="me-8 mt-8 mb-8"
                :class="mobile ? 'text-center' : 'text-left'"
              >
                <span
                  class="highlight-white"
                  :class="mobile ? '' : 'text-heading'"
                  >¡Quedan pocos días, prepárate para las postulaciones!</span
                >
              </h3>
              <div :class="mobile ? 'text-body' : 'text-subtitle'">
                Podemos <b>acompañarte durante todas las etapas del SAE</b>,
                entregándote
                <b
                  >información personalizada que te ayudará a tener una mejor
                  postulación.</b
                >
              </div>
            </div>
            <div v-else></div>
          </div>
          <div
            class="relative flex-grow-1"
            :class="
              mobile ? 'flex-grow-1' : 'flex-equal d-flex flex-column ms-8 me-8'
            "
          >
            <div
              class=""
              :class="mobile ? 'text-body' : 'mt-auto text-subtitle'"
            >
              <b
                >¿Quieres seguir recibiendo información personalizada sobre el
                proceso?</b
              >
            </div>
            <div class="d-flex mt-4" :class="mobile ? '' : 'mb-auto'">
              <v-btn
                outlined
                class="flex-fill me-1 bg--sae-primary color--neutral-100-t"
                @click="goTo('contact-info')"
                >Sí</v-btn
              >
              <v-btn
                outlined
                style="background: white !important"
                class="flex-fill ms-1 color--sae-primary"
                @click="
                  goTo('ending');
                  continueWithoutInfo('ending');
                "
                >No</v-btn
              >
            </div>
          </div>
        </template>
      </screen>
    </div>
    <!-- b.2 -->
    <div v-else-if="screen == 'contact-info'">
      <screen
        key="contact-info"
        ref="contact-info"
        :screens="screens"
        @goBack="goBack"
      >
        <template v-slot:icons>
          <img
            src="@/assets/PencilDown.png"
            style="position: absolute; height: 127px; top: 0; right: 15px"
          />
          <img
            v-if="mobile"
            src="@/assets/BasketBallRight.png"
            style="position: absolute; height: 97px; left: 0; top: 10vh"
          />
          <img
            v-else
            src="@/assets/BasketBallFull.png"
            style="position: absolute; height: 97px; left: 20vw; top: 10vh"
          />
        </template>
        <template v-slot:content>
          <div :class="mobile ? '' : 'flex-equal'">
            <div
              v-if="personalized || (!personalized && students.length > 0)"
              :class="mobile ? '' : 'mt-10'"
            >
              <h3
                style="position: relative; z-index: 6"
                :class="
                  mobile
                    ? 'ms-3 me-3 text-center'
                    : 'text-left text-heading mb-10'
                "
              >
                <span class="highlight-white"
                  >¡Quedan pocos días, prepárate para las postulaciones!</span
                >
              </h3>
              <div class="text-body">
                Podemos <b>acompañarte durante todas las etapas del SAE</b>,
                entregándote
                <b
                  >información personalizada que te ayudará a tener una mejor
                  postulación.<br />
                  <br />
                  Completa con tus datos para recibir información:</b
                >
                <br />
              </div>
            </div>
            <div
              v-else
              style="position: relative"
              :class="mobile ? '' : 'mt-10'"
            >
              <h3 style="z-index: 10">
                Para poder entregar información personalizada, necesitamos
                algunos datos adicionales.
              </h3>
            </div>
          </div>
          <div
            class="relative"
            :class="mobile ? 'd-block' : 'flex-equal d-flex flex-column'"
          >
            <div
              class="relative"
              :style="mobile ? '' : 'max-height:100%;overflow-y: auto'"
              :class="mobile ? 'd-block' : 'flex-equal d-flex flex-column '"
            >
              <div :class="mobile ? '' : 'flex-fill'"></div>
              <!-- aqui -->
              <div
                v-if="
                  (!personalized && students.length == 0) ||
                  foundStudentLast ||
                  confirmedLast
                "
              >
                <sae-input label="Nombre escuela" disabled :value="schoolName">
                </sae-input>
                <sae-input label="Curso" disabled :value="currentSchoolGrade">
                </sae-input>
                <div
                  v-if="emphasis"
                  class="pa-2 rounded text-body"
                  style="
                    background: #e0e9fb;
                    color: #3662e3;
                    margin-bottom: 10px;
                  "
                >
                  Intenta ingresando los
                  <b>nombres y dos apellidos del estudiante</b>
                </div>
                <sae-input
                  :disabled="confirmedLast"
                  :label="
                    confirmedLast
                      ? 'Nombre completo del estudiante'
                      : 'Ingrese nombre completo del estudiante'
                  "
                  v-model="studentName"
                  :autofocus="mobile ? false : true"
                >
                </sae-input>
                <div
                  v-if="
                    foundStudentLast &&
                    tempStudents.length > 0 &&
                    !confirmedLast
                  "
                >
                  <v-card
                    rounded
                    no-elevation
                    class="elevation-0 pa-3 rounded-lg bg--sae-primary-90-t"
                  >
                    Tenemos en nuestros registros a:<br />
                    <b>{{ tempStudents[0].name }}</b>
                    <br />
                    ¿Corresponde a tu estudiante?
                    <br />
                    <div class="d-flex w-100 mt-5">
                      <v-btn
                        outlined
                        style="background: white !important"
                        class="flex-fill me-1 color--sae-primary"
                        @click="confirmStudentLast"
                        >Sí</v-btn
                      >
                      <v-btn
                        outlined
                        style="background: white !important"
                        class="flex-fill ms-1 color--sae-primary"
                        @click="rejectStudentLast"
                        >No</v-btn
                      >
                    </div>
                  </v-card>
                  <br />
                </div>
                <div
                  class="d-flex w-100"
                  style="gap: 10px"
                  v-if="!confirmedLast"
                  :class="mobile ? 'flex-column' : 'flex-row'"
                >
                  <sae-button
                    class="flex-equal mb-4"
                    :buttonStyle="{ width: '100%' }"
                    v-if="!foundStudentLast"
                    @click="findStudentLast"
                    :disabled="studentName.length < 5"
                    >Buscar estudiante</sae-button
                  >
                </div>
                <div class="mb-2 mt-4 bold text-body">
                  Por último, necesitamos algunos datos para poder contactarte.
                </div>
              </div>
              <sae-input
                :class="mobile ? '' : 'flex-grow-0 '"
                :autofocus="
                  (!personalized && students.length == 0) || mobile
                    ? false
                    : true
                "
                label="Nombre completo"
                placeholder="Nombre, Apellidos"
                v-model="parentName"
              ></sae-input>
              <div
                :class="mobile ? '' : 'flex-grow-0'"
                class="color--error text-label"
                v-if="wroteParent && parentName.length == 0"
              >
                El nombre no puede estar vacío.
              </div>
              <sae-input
                :class="mobile ? '' : 'flex-grow-0'"
                label="Correo electrónico"
                v-model="parentEmail"
                placeholder="tucorreo@correo.com"
              ></sae-input>
              <div
                :class="mobile ? '' : 'flex-grow-0'"
                class="color--error text-label"
                v-if="wroteEmail && !validateEmail(parentEmail)"
              >
                El email es incorrecto.
              </div>
              <div
                :class="mobile ? '' : 'flex-grow-0'"
                style="
                  border-radius: 5px 5px 0px 0px !important;
                  background: #f8fafc !important;
                  overflow-y: hidden;
                  position: relative;
                "
                :style="{
                  maxHeight: mobile ? '49px' : '60px',
                  height: mobile ? '49px' : '60px',
                  minHeight: mobile ? '49px' : '60px',
                }"
              >
                <div
                  class="text-body"
                  style="
                    position: absolute;
                    top: 50%;
                    margin-top: 7px;
                    left: 43px;
                    transform: translate(0, -50%);
                    z-index: 30;
                  "
                  :style="mobile ? 'margin-top: 7px;' : 'margin-top: 3px;'"
                >
                  +{{ dialCode }}
                </div>
                <vue-tel-input-vuetify
                  label="Celular"
                  @country-changed="setDialCode"
                  placeholder="Número telefónico"
                  defaultCountry="CL"
                  ref="phone"
                  v-model="phone"
                >
                </vue-tel-input-vuetify>
              </div>
              <div
                :class="mobile ? '' : 'flex-grow-0'"
                class="color--error text-label"
                v-if="wrotePhone && !validatePhone(phone)"
              >
                El número de celular es incorrecto.
              </div>
              <div
                v-if="!mobile"
                :class="mobile ? '' : 'flex-grow-0'"
                class="mt-4 d-flex"
              >
                <div class="me-2">*</div>
                <div class="text-body bold">
                  Confirmando tus datos estarás de acuerdo que te contactemos
                  vía correo electrónico y/o WhatsApp
                </div>
              </div>
            </div>
            <div
              class="d-flex w-100 mt-4 mb-4"
              style="gap: 10px"
              :style="
                mobile
                  ? ''
                  : !personalized && students.length == 0
                  ? ''
                  : 'margin-bottom:50px !important'
              "
              :class="mobile ? 'flex-column' : 'flex-row flex-grow-0'"
            >
              <sae-button
                class="flex-equal"
                :buttonStyle="{ width: '100%' }"
                :disabled="
                  (!validateEmail(parentEmail) || !validateName(parentName)) &&
                  (!validateName(parentName) || !validatePhone(phone))
                "
                @click="sendData"
                >Confirmar mis datos</sae-button
              >
              <sae-button
                :primary="false"
                class="flex-equal"
                :buttonStyle="{ width: '100%' }"
                @click="
                  goTo('ending');
                  continueWithoutInfo('ending');
                "
                >Continuar sin entregar información</sae-button
              >
            </div>
            <div v-if="mobile" class="d-flex">
              <div class="me-2">*</div>
              <div class="text-body bold">
                Confirmando tus datos estarás de acuerdo que te contactemos vía
                correo electrónico y/o WhatsApp
              </div>
            </div>
          </div>
        </template>
      </screen>
    </div>
    <!-- esta screen -->
    <div v-else-if="screen == 'ending'">
      <screen
        key="ending"
        ref="ending"
        v-if="screen == 'ending'"
        :screens="screens"
        @goBack="goBack"
      >
        <template v-slot:icons>
          <div></div>
        </template>
        <template v-slot:content>
          <div style="width: 100%; max-width: 800px" class="ms-auto me-auto">
            <div class="h-100 d-flex flex-column" style="height: 100%">
              <img
                src="@/assets/Objects.png"
                class="mb-4 mt-4 ms-auto me-auto"
                style="max-width: 80%; width: 80%"
              />
              <h1 style="color: white" class="text-center ms-4 me-4">
                <span>
                  Muchas gracias y éxito en el proceso de postulación al
                  SAE</span
                >
              </h1>
              <div style="color: white" class="mt-3 text-center">
                Si quieres saber más sobre el SAE, te invitamos a visitar:
              </div>
              <div
                class="d-flex w-100"
                style="gap: 10px"
                :class="mobile ? 'flex-column' : 'flex-row'"
              >
                <div class="flex-equal"></div>
                <v-btn
                  rounded
                  class="text-none"
                  :class="mobile ? 'w-100 mt-1' : 'flex-equal mt-2'"
                  style="background: white; color: #3662e3"
                  @click="
                    openLink({
                      link: 'https://www.sistemadeadmisionescolar.cl/',
                      from: 'sitio-web-sae',
                    })
                  "
                  >Sitio web del SAE</v-btn
                >
                <v-btn
                  rounded
                  class="primary text-none"
                  :class="mobile ? 'w-100 mt-1' : 'flex-equal mt-2'"
                  @click="
                    openLink({
                      link: 'https://www.sistemadeadmisionescolar.cl/buscador.html',
                      from: 'puntos-de-apoyo',
                    })
                  "
                  >Puntos de apoyo</v-btn
                >
                <div class="flex-equal"></div>
              </div>
              <div class="w-100 text-center" :class="mobile ? 'mt-1' : 'mt-2'">
                <v-btn
                  rounded
                  class="primary text-none"
                  :class="mobile ? 'w-100' : 'flex-equal'"
                  @click="
                    openLink({
                      link: 'https://www.sistemadeadmisionescolar.cl/encuentro.html',
                      from: 'establecimientos',
                    })
                  "
                  >Establecimientos que puedes visitar</v-btn
                >
              </div>

              <div class="text-center mt-4" style="color: white">
                <div class="d-flex">
                  <v-icon
                    class="mt-auto mb-auto ms-auto me-1"
                    x-small
                    color="white"
                    >mdi-facebook</v-icon
                  >
                  <div class="text-label me-1">|</div>
                  <div
                    @click="
                      openLink({
                        link: 'https://www.facebook.com/SistemaAdmisionEscolar/',
                        from: 'sistema-admision-escolar',
                      })
                    "
                    class="clickable me-auto text-label color--neutral-100-t"
                  >
                    Sistema de admisión escolar
                  </div>
                </div>
                <div class="d-flex">
                  <v-icon
                    class="mt-auto mb-auto ms-auto me-1"
                    x-small
                    color="white"
                    >mdi-twitter</v-icon
                  >
                  <div class="text-label me-1">|</div>
                  <div
                    @click="
                      openLink({
                        link: 'https://twitter.com/admisionescolar?lang=en',
                        from: 'twitter-admision-escolar',
                      })
                    "
                    class="me-2 text-label color--neutral-100-t clickable"
                  >
                    @AdmisionEscolar
                  </div>
                  <v-icon class="mt-auto mb-auto me-1" x-small color="white"
                    >mdi-instagram</v-icon
                  >
                  <div class="text-label me-1">|</div>
                  <div
                    @click="
                      openLink({
                        link: 'https://www.instagram.com/Admisionescolar/',
                        from: 'instagram-admision-escolar',
                      })
                    "
                    class="clickable me-auto text-label color--neutral-100-t"
                  >
                    @AdmisionEscolar
                  </div>
                </div>
                <div class="d-flex">
                  <v-icon
                    class="mt-auto mb-auto ms-auto me-1"
                    x-small
                    color="white"
                    >mdi-phone</v-icon
                  >

                  <div class="me-auto text-label">| 600 600 26 26</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </screen>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import Screen from "@/components/Screen.vue";
export default {
  name: "Form",

  mounted() {
    // always write uuid
    this.updateUUID({ uuid: this.generateUUID() });
    if ("id_familia" in this.$route.params) {
      this.personalized = true;
      this.setPersonalized({ personalized: true });
      this.familyId = this.$route.params.id_familia;
      this.track({
        event: "open-personalized",
        data: { id_family_group: this.familyId },
      });
      this.request({
        endpoint: "sae_student",
        params: { id_family_group: this.familyId },
        type: "get",
      }).then((response) => {
        let students = response.data;
        students.sort((a, b) =>
          a.has_intervention === b.has_intervention
            ? 0
            : a.has_intervention
            ? -1
            : 1
        );
        this.students = students;
        // let's create temporal legal guardian
        this.request({
          type: "post",
          endpoint: "sae_legal_guardian/",
          body: {
            uuid: this.uuid,
            id_family_group: this.students[0].id_family_group,
          },
        })
          .then((response) => {
            this.legalGuardianId = response.data.id;
            this.loaded = true;
          })
          .catch(() => {
            //this.legalGuardianId = response.data.id;
            this.loaded = true;
          });
      });
    } else if ("rbd" in this.$route.params && "grado" in this.$route.params) {
      this.setPersonalized({ personalized: false });
      this.rbd = this.$route.params.rbd;
      this.grade = this.$route.params.grado;
      this.track({
        event: "open-quasi-personalized",
        data: { rbd: this.rbd, grado: this.grade },
      });
      this.request({
        endpoint: "sae_institution_grade",
        params: { id: `${this.rbd}-${this.grade}` },
        type: "get",
      }).then((response) => {
        this.gradeInfo = response.data[0];
        this.loaded = true;
      });
    }
  },
  data() {
    return {
      confirmedLast: false,
      giveInfo: true,
      legalGuardianId: null,
      loaded: false,
      wroteParent: false,
      wroteEmail: false,
      wrotePhone: false,
      familyId: null,
      rbd: null,
      grade: null,
      gradeInfo: {},
      students: [],
      tempStudents: [],
      screen: "intro",
      personalized: false,
      screens: [],
      studentName: "",
      parentName: "",
      parentEmail: "",
      dialCode: "56",
      phone: "9",
      foundStudent: false,
      foundStudentLast: false,
      emphasis: false,
    };
  },
  components: { Screen },
  watch: {
    phone() {
      this.dialCode = this.$refs.phone.countryCode.dialCode;
      this.wrotePhone = true;
    },
    parentName() {
      this.wroteParent = true;
    },
    parentEmail() {
      this.wroteEmail = true;
    },
  },
  methods: {
    ...mapActions({
      request: "request",
      openLink: "openLink",
      updateUUID: "updateUUID",
      track: "track",
      setPersonalized: "setPersonalized",
    }),
    continueWithoutInfo(screen) {
      this.track({
        event: "continue-without-info",
        data: {
          fromScreen: this.screen,
          toScreen: screen,
        },
      });
    },
    sendData() {
      if (this.legalGuardianId) {
        let body = {
          name: this.parentName,
          fullname: this.parentName,
          cellphone: this.phone,
          mail: this.parentEmail,
        };
        this.request({
          type: "patch",
          endpoint: `sae_legal_guardian/${this.legalGuardianId}/`,
          body,
        }).then(() => {
          this.track({ event: "send-data", data: body });
          this.goTo("ending");
        });
      } else {
        this.goTo("ending");
      }
    },
    validateEmail(email) {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    validateName(name) {
      return name.length > 0;
    },
    validatePhone(phone) {
      return phone.length == 11;
    },
    rejectStudent() {
      this.foundStudent = false;
      this.emphasis = true;
      this.track({
        event: "wrong-student",
        data: {
          searched: this.studentName,
          found: this.students[0].name,
        },
      });
    },
    rejectStudentLast() {
      this.foundStudentLast = false;
      this.emphasis = true;
      this.track({
        event: "wrong-student-contact-info",
        data: {
          searched: this.studentName,
          found: this.tempStudents[0].name,
        },
      });
    },
    confirmStudent() {
      this.foundStudent = false;
      this.track({
        event: "confirm-student",
        data: { id_oap: this.students[0].id_oap },
      });
      // get all students from family
      this.request({
        type: "get",
        endpoint: "sae_student/",
        params: { id_family_group: this.students[0].id_family_group },
      }).then((response) => {
        let tempStudents = response.data;
        let currentStudent = this.students[0].id_oap;
        tempStudents.forEach((ts) => {
          if (ts.id_oap != currentStudent) {
            this.students.push(ts);
          }
        });
        // let's create temporal uuid
        this.request({
          type: "post",
          endpoint: "sae_legal_guardian/",
          body: {
            uuid: this.uuid,
            id_family_group: this.students[0].id_family_group,
          },
        })
          .then((response) => {
            this.legalGuardianId = response.data.id;
            if (this.students.length > 1) {
              this.goTo("other-students");
            } else {
              this.goTo("web-platform");
            }
          })
          .catch(() => {
            if (this.students.length > 1) {
              this.goTo("other-students");
            } else {
              this.goTo("web-platform");
            }
          });
      });
    },
    confirmStudentLast() {
      this.foundStudentLast = false;
      this.track({
        event: "confirm-student-contact-info",
        data: { id_oap: this.tempStudents[0].id_oap },
      });
      // get all students from family
      this.request({
        type: "get",
        endpoint: "sae_student/",
        params: { id_family_group: this.tempStudents[0].id_family_group },
      }).then((response) => {
        let tempStudents = response.data;
        let currentStudent = this.tempStudents[0].id_oap;
        tempStudents.forEach((ts) => {
          if (ts.id_oap != currentStudent) {
            this.students.push(ts);
          }
        });
        // let's patch
        this.request({
          type: "post",
          endpoint: `sae_legal_guardian/`,
          body: {
            uuid: this.uuid,
            id_family_group: this.tempStudents[0].id_family_group,
          },
        }).then((response) => {
          this.legalGuardianId = response.data.id;
        });
        // let's change
        this.confirmedLast = true;
        this.studentName = this.tempStudents[0].name;
      });
    },
    findStudentLast() {
      this.request({
        type: "post",
        endpoint: "search/",
        body: {
          search: this.studentName,
          institution_grade_id: this.gradeInfo.id,
        },
      }).then((response) => {
        this.track({
          event: "search-student-contact-info",
          data: {
            searched: this.studentName,
            found: response.data.name,
            foundId: response.data.id_oap,
          },
        });
        this.tempStudents = [response.data];
        this.foundStudentLast = true;
        this.personalized = true;
        this.emphasis = false;
      });
    },
    findStudent() {
      this.request({
        type: "post",
        endpoint: "search/",
        body: {
          search: this.studentName,
          institution_grade_id: this.gradeInfo.id,
        },
      }).then((response) => {
        this.track({
          event: "search-student",
          data: {
            searched: this.studentName,
            found: response.data.name,
            foundId: response.data.id_oap,
          },
        });
        this.students = [response.data];
        this.foundStudent = true;
        this.personalized = true;
        this.emphasis = false;
      });
    },
    generateUUID() {
      return uuidv4();
    },
    setDialCode() {
      this.dialCode = this.$refs.phone.countryCode.dialCode;
    },
    goBack() {
      let screen = this.screens.pop();
      this.track({ event: "go-back-screen", data: { screen } });
      this.screen = screen;
    },
    goTo(screen) {
      this.emphasis = false;
      this.foundStudentLast = false;
      this.track({
        event: "change-screen",
        data: { from: this.screen, to: screen },
      });
      this.screens.push(this.screen);
      this.screen = screen;
      if (screen == "contact-info") {
        this.$nextTick(() => {
          this.dialCode = "56";
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      mobile: "mobile",
      screenWidth: "screenWidth",
      uuid: "uuid",
      showMixpanel: "showMixpanel",
    }),
    isProd() {
      return process.env.VUE_APP_ENVIRONMENT == "prod";
    },
    havePIE() {
      return this.students.filter((s) => s.is_pie);
    },
    havePriority() {
      return this.students.filter((s) => s.has_priority);
    },
    markLabel() {
      if (this.students.length > 0) {
        return this.students[0].grade.mark_label.id;
      }
      if (this.gradeInfo.mark_label) {
        return this.gradeInfo.mark_label.id;
      }
      return 0;
    },
    currentSchoolGrade() {
      if (this.students.length > 0) {
        return this.students[0].grade.grade_label.name;
      }
      if (this.gradeInfo.mark_label) {
        return this.gradeInfo.grade_label.name;
      }
      return 0;
    },
    nextSchoolGrade() {
      if (this.students && this.students.length > 0) {
        return this.students[0].grade.next_grade_label.name;
      }
      if (this.gradeInfo.mark_label) {
        return this.gradeInfo.next_grade_label.name;
      }
      return 0;
    },
    schoolName() {
      if (this.students.length > 0) {
        return this.students[0].grade.institution.name;
      }
      if (this.gradeInfo.institution) {
        return this.gradeInfo.institution.name;
      }
      return "";
    },
    welcomeText() {
      if (this.markLabel == 3) {
        return "no tiene continuidad de estudios desde medio mayor a";
      }
      if (this.markLabel == 1 || this.markLabel == 4) {
        return "no tiene continuidad de estudios a";
      }
      if (this.markLabel == 2) {
        return "no tiene continuidad de estudios desde modalidad TEL a";
      }
      return "";
    },
    studentNames() {
      if (this.students.length > 1) {
        let studentNames = "";
        let students = [...this.students];
        let lastStudent = students.pop();
        studentNames = `${students.map((s) => s.name).join(", ")} y ${
          lastStudent.name
        }`;
        return studentNames;
      }
      if (this.students.length == 0) {
        return "";
      }
      return this.students[0].name;
    },
    background() {
      if (["intro", "ending"].includes(this.screen)) {
        return "#68CAD6";
      }

      if (
        [
          "input-name",
          "other-students",
          "web-platform",
          "applications-coming",
          "how-to-apply",
          "vacancies",
          "student-can-apply",
          "student-is-priority",
          "contact-info",
          "contact-info-previous",
        ].includes(this.screen)
      ) {
        return "#EFF6FF";
      }

      return "white";
    },
  },
};
</script>
<style>
.web-platform {
  background: rgb(68, 202, 221);
  background: -moz-linear-gradient(
    180deg,
    rgba(68, 202, 221, 1) 0%,
    rgba(148, 135, 254, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(68, 202, 221, 1) 0%,
    rgba(148, 135, 254, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(68, 202, 221, 1) 0%,
    rgba(148, 135, 254, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#44cadd",endColorstr="#9487fe",GradientType=1);
}
.circle {
  display: inline-block;
  border: 2px solid white;
  padding: 3px 10px 3px 10px;
  border-radius: 30px;
}
.highlight {
  background: #ceecf1;
  color: #3662e3;
  padding-left: 2px;
  padding-right: 2px;
  display: inline;
}
.highlight-white {
  background: #66d6e7;
  color: white;
  padding-left: 2px;
  padding-right: 2px;
  display: inline;
}
.highlight-dark {
  background: #f8fafc;
  color: #1fbdd3;
  padding-left: 2px;
  padding-right: 2px;
  display: inline;
}
.screen {
  display: flex;
  flex-direction: row;
  height: 100%;
}

@media (max-width: 740px) {
  .screen {
    flex-direction: column;
  }
}
@media (min-width: 740px) {
  .screen {
    margin: 100px;
  }
}
.form-input {
  max-height: 60px;
  height: 60px;
}
.form-input > .v-input__control {
  background: #f8fafc !important;
}
.arrow-left {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
}
.dot {
  width: 4px;
  height: 4px;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 8px;
  border-radius: 50%;
  background-color: #3662e3;
  box-shadow: 0 0 5px 5px rgba(54, 98, 227, 0.5);
}
.flex-equal {
  flex: 1;
}
.position-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
