import Vue from "vue";
import VueRouter from "vue-router";
import Unregistered from "../views/Unregistered.vue";
// import Application from "../views/Application.vue";
// import Score from "../views/Score.vue";
// import Playground from "../views/Playground.vue";
import Blocker from "../views/Blocker.vue";
import Form from "../views/Form.vue";

Vue.use(VueRouter);

const routes = [
  ...(["staging", "local", "develop"].includes(process.env.VUE_APP_ENDPOINT) ||
  ["local", "develop", "staging"].includes(process.env.VUE_APP_ENVIRONMENT)
    ? [
        {
          path: "/playground",
          name: "playground",
          // component: Playground,
          component: Blocker,
        },
      ]
    : []),
  {
    path: "/3/:id?",
    name: "unregistered",
    component: Unregistered,
    // component: Blocker,
  },
  {
    path: "/2/:id?",
    name: "application",
    // component: Application,
    component: Blocker,
  },
  {
    path: "/1/:id?",
    name: "score",
    // component: Score,
    component: Blocker,
  },
  // sae
  {
    path: "/sae/",
    name: "cartilla",
    component: Form,
  },
  {
    path: "/sae/:id_familia/",
    name: "cartilla",
    component: Form,
  },
  {
    path: "/sae/:rbd/:grado/",
    name: "cartilla",
    component: Form,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
