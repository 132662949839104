<template>
  <div>
    <v-btn
      :class="
        [
          primary
            ? 'bg--sae-primary color--neutral-100-t'
            : 'bg--neutral-100-t color--sae-primary',
          buttonClass,
        ].join(' ')
      "
      class="text-none flex-fill"
      @click="$emit('click')"
      :rounded="rounded"
      :disabled="disabled"
      :style="{
        background: disabled ? 'lightgray !important' : '',
        ...buttonStyle,
      }"
      style="letter-spacing: 0.5px !important"
      v-bind="{ ...$attrs }"
    >
      <slot> </slot>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "SaeButton",
  props: {
    primary: {
      type: Boolean,
      required: false,
      default: true,
    },
    rounded: {
      required: false,
      default: true,
      type: Boolean,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    buttonClass: {
      required: false,
      default: "",
      type: String,
    },
    buttonStyle: {
      required: false,
      default: () => {},
      type: Object,
    },
  },
};
</script>
<style></style>
