<template>
  <svg
    width="46"
    height="44"
    viewBox="0 0 46 44"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.27149e-07 22.6223C7.38234e-07 34.3146 22.8649 22.6223 22.8649 22.6223C22.8649 22.6223 -2.83936e-07 10.9301 2.27149e-07 22.6223Z"
    />
    <path
      d="M45.7305 22.6223C45.7305 34.3146 22.8656 22.6223 22.8656 22.6223C22.8656 22.6223 45.7305 10.9301 45.7305 22.6223Z"
    />
    <path
      d="M11.9775 41.4856C21.7706 47.1396 23.2293 21.9968 23.2293 21.9968C23.2293 21.9968 2.18439 35.8315 11.9775 41.4856Z"
    />
    <path
      d="M34.4775 2.51291C44.2706 8.16695 23.2257 22.0016 23.2257 22.0016C23.2257 22.0016 24.6844 -3.14113 34.4775 2.51291Z"
    />
    <path
      d="M34.4756 41.4856C24.6826 47.1396 23.2238 21.9968 23.2238 21.9968C23.2238 21.9968 44.2687 35.8315 34.4756 41.4856Z"
    />
    <path
      d="M11.9756 2.51291C2.18255 8.16695 23.2275 22.0016 23.2275 22.0016C23.2275 22.0016 21.7687 -3.14113 11.9756 2.51291Z"
    />
  </svg>
</template>
<script>
export default {
  name: "Flower.svg",
};
</script>
