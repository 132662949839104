<template>
  <svg
    width="27"
    height="28"
    viewBox="0 0 27 28"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.67943 10.1538C6.16114 9.20784 5.86932 8.13924 5.86932 7.00804C5.86932 3.13761 9.28569 0 13.5 0V6.30956C14.0757 5.3928 14.8748 4.60629 15.877 4.03612C19.2975 2.09014 23.788 3.40121 25.9068 6.96447L20.2997 10.1545C21.4023 10.1825 22.5016 10.4662 23.5104 11.0316C26.9435 12.956 28.028 17.4154 25.9326 20.992L20.3206 17.8462C20.8389 18.7922 21.1307 19.8608 21.1307 20.992C21.1307 24.8624 17.7143 28 13.5 28V21.6904C12.9243 22.6072 12.1252 23.3937 11.123 23.9639C7.70254 25.9099 3.21203 24.5988 1.09316 21.0355L6.70032 17.8455C5.59765 17.8175 4.49838 17.5338 3.48963 16.9684C0.0564756 15.044 -1.02798 10.5846 1.06743 7.00805L6.67943 10.1538ZM13.504 13.9839C13.5027 13.9816 13.5013 13.9794 13.5 13.9771L13.5 13.9839C13.5013 13.9839 13.5027 13.9839 13.504 13.9839ZM13.5 14.0161V14.0229L13.496 14.0161L13.5 14.0161Z"
    />
  </svg>
</template>
<script>
export default {
  name: "Shuriken.svg",
};
</script>
