var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',{staticClass:"mt-4 rounded bg--primary-90-t",staticStyle:{"background":"#f4f8ff"}},[_c('v-expansion-panel-header',[_c('common-panel-header',{staticClass:"d-flex flex-column text-left",attrs:{"reference":"career","panelData":{
        careerName: _vm.career.name,
        careerInstitution: _vm.institutionName,
        careerCode: _vm.career.code,
        source: _vm.source,
      },"selected":_vm.selected}},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.career.name))])]),_c('div',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.institutionName)+" ")])])],1),_c('v-expansion-panel-content',[_c('div',{staticClass:"mt-2 mb-2 bg--neutral-100-t pa-2 text-center rounded text-subtitle"},[_c('div',{staticClass:"w-100 text-left"},[_c('v-icon',{staticClass:"ms-1 mr-auto",attrs:{"large":"","color":"#1DB396"}},[_vm._v("mdi-note-text-outline")])],1),_c('div',{staticClass:"text-left text-body ms-2 color--tertiary-20-s mb-2"},[_vm._v(" Estas son las pruebas que este programa requiere para postular. ")]),_c('div',[_c('v-divider'),_c('div',{staticClass:"d-flex text-center text-body w-100 justify-content-center mt-2 mb-2"},[_c('div',{staticClass:"mr-2 ms-auto"},[_c('v-icon',{attrs:{"color":"#48DEC0"}},[_vm._v("mdi-square-rounded")]),_vm._v(" Pruebas requeridas ")],1),_c('div',{staticClass:"me-auto"},[_c('v-icon',{attrs:{"color":"#EAEAEB"}},[_vm._v("mdi-square-rounded")]),_vm._v(" Pruebas no requeridas ")],1)]),_c('v-divider')],1),_vm._l(([
          'Competencia Lectora',
          'Competencia Matemática (M1)',
          'Competencia Matemática (M2)',
        ]),function(test,index){return _c('div',{key:index,staticClass:"pa-2 ps-4 ma-1 rounded text-left text-body",class:_vm.career.tests.includes(test)
            ? 'bg--tertiary-50-t bold'
            : 'bg--neutral-90-t color--neutral-70-t'},[_vm._v(" • "+_vm._s(test)+" ")])}),(_vm.hasBoth)?_c('div',{staticClass:"pa-2 ps-4 ma-1 rounded text-left text-body bg--tertiary-50-t bold"},[_vm._v(" • Historia y Ciencias Sociales o Ciencias ")]):_c('div',_vm._l(([
            'Historia y Ciencias Sociales',
            'Ciencias',
          ]),function(test,index){return _c('div',{key:index,staticClass:"pa-2 ps-4 ma-1 rounded text-left text-body",class:_vm.career.tests.includes(test)
              ? 'bg--tertiary-50-t bold'
              : 'bg--neutral-90-t color--neutral-70-t'},[_vm._v(" • "+_vm._s(test)+" ")])}),0)],2),(_vm.showScoreInformation)?_c('div',{staticClass:"d-flex text-left flex-row justify-contents-center align-items-center mt-2 mb-2"},[_c('v-icon',{staticClass:"me-2",attrs:{"color":"#1161F8","x-large":""}},[_vm._v("mdi-note-text-outline")]),_c('div',{staticClass:"bold mt-2"},[_vm._v("Información general")])],1):_vm._e(),_c('common-information',{attrs:{"title":"Acreditación institucional"},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Para conocer la acreditación de las instituciones revisa en la Comisión Nacional de Acreditación (CNA), "),_c('span',{staticClass:"color--primary bold",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openLink({
              from: 'career',
              link: 'https://www.cnachile.cl/Paginas/preguntasfrecuentes.aspx',
            })}}},[_vm._v("haz click aquí")]),_vm._v(". ")]},proxy:true}])},[(_vm.accreditation === null)?_c('div',[_vm._v("Sin información.")]):(_vm.accreditation == 0)?_c('div',[(
            [895, 896, '895', '896'].includes(_vm.career.campus.institution.code)
          )?_c('span',[_vm._v("Bajo tutela")]):_c('span',[_vm._v("Institución no acreditada.")]),_c('span')]):_c('div',[_c('v-icon',{staticClass:"me-2",attrs:{"color":"#48DEC0"}},[_vm._v("mdi-check-decagram-outline")]),_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.institutionName)+" ")]),_vm._v(" acreditada por "+_vm._s(_vm.accreditation)+" años. ")],1)]),_c('common-information',{attrs:{"title":"Gratuidad"},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Para conocer las instituciones que cuentan con gratuidad, "),_c('span',{staticClass:"color--primary bold",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openLink({
              from: 'career',
              link: 'https://portal.beneficiosestudiantiles.cl/gratuidad#references_tabs_field_asociar_subproceso_tab2',
            })}}},[_vm._v("haz click aquí")]),_vm._v(". ")]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.benefits == true ? "Esta carrera es elegible para gratuidad." : _vm.benefits == false ? "Esta carrera no es elegible para gratuidad." : "Sin información.")+" ")]),_c('common-information',{attrs:{"title":"Duración oficial","tooltip":"La duración oficial es la duración de la carrera por malla curricular."}},[(_vm.duration)?_c('div',[_vm._v(_vm._s(_vm.duration)+" semestres.")]):_c('div',[_vm._v("Sin información.")])]),_c('common-information',{attrs:{"title":"Costo anual 2023","tooltip":"Corresponde al pago anual que deberás hacer a la institución por tus estudios. Para mayor información consulta en la institución."}},[_vm._v(" $"+_vm._s(_vm.tuition ? _vm.tuition.toLocaleString("es-CL") : "Sin información.")+" ")]),(_vm.showAdditionalInformation)?_c('v-expansion-panels',{model:{value:(_vm.complementaryExpanded),callback:function ($$v) {_vm.complementaryExpanded=$$v},expression:"complementaryExpanded"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('common-panel-header',{attrs:{"reference":"complementary","panelData":{
              careerName: _vm.career.name,
              careerInstitution: _vm.institutionName,
              careerCode: _vm.career.code,

              source: _vm.source,
            },"selected":_vm.complementaryExpanded === 0}},[_c('v-icon',{staticClass:"color--primary me-2"},[_vm._v("mdi-file-document-plus-outline")]),_c('span',{staticStyle:{"color":"#062053"}},[_c('b',[_vm._v("Información complementaria")])])],1)],1),_c('v-expansion-panel-content',[_c('common-information',{attrs:{"title":"Empleabilidad","background-color":"#CFDFFE","tooltip":"Este indicador mide la proporción de titulados de una carrera que obtiene un ingreso superior al ingreso mínimo al cabo del primer año después de su titulación."}},[_c('div',{staticClass:"color--primary-40-t"},[(_vm.employability)?_c('div',[_vm._v(" "+_vm._s(_vm.employability.toLocaleString("es-CL"))+"% "),_c('br'),_vm._v(" personas empleadas al primer año ")]):_c('div',[_vm._v("Sin información.")])])]),_c('common-information',{attrs:{"title":"Ingreso","tooltip":"Los ingresos al 4° año de titulación corresponden al promedio de ingresos percibidos por las cohortes de titulados 2015, 2016 y 2017.","background-color":"#CFDFFE"}},[_c('div',{staticClass:"color--primary-40-t"},[_vm._v(" "+_vm._s(_vm.salary ? _vm.salary : "Sin información.")+" "),_c('br')])])],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }